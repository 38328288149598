import { createSlice } from '@reduxjs/toolkit'

import { ISiteSelector } from '@/common/interfaces/site-selector.interface'
import {
  errorDeleteItem,
  errorItemData,
  errorListData,
  errorPostPutItemData,
  receiveDeleteItem,
  receiveItemData,
  receiveListData,
  receivePostPutItemData,
  requestDeleteItem,
  requestItemData,
  requestListData,
  requestPostPutItemData,
} from '@/common/utils/state.utils'

import { ISiteSchedule } from '@/features/sites/interfaces/site-building-schedule.interface'
import {
  ICurrentCustomers,
  IPackage,
  IPackageAdd,
  IPackages,
  IPendingCustomers,
  ISInventory,
  ISInventoryDetails,
  ISInventoryItemUpdate,
  ISite,
  ISProducts,
  ISProductsCreate,
  IStockMovement,
} from '@/features/sites/interfaces/site.interface'
import { sitesService } from '@/features/sites/state/sites/sites.service'
import { ISiteState, SiteState } from '@/features/sites/state/sites/sites.state.interface'

const initialState = new SiteState()

const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    storeSelectedSite(state, action) {
      return { ...state, selectedSite: action.payload }
    },
    resetSliceError(state, action) {
      return { ...state, [action.payload]: { ...state[action.payload as keyof typeof initialState], errors: null } }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sitesService.fetchSites.pending, (state) => {
        return requestListData<ISiteState, ISite[]>({ ...state }, ['sites'])
      })
      .addCase(sitesService.fetchSites.fulfilled, (state, action) => {
        return receiveListData<ISiteState, ISite[]>({ ...state }, ['sites'], action.payload.sites)
      })
      .addCase(sitesService.fetchSites.rejected, (state, action) => {
        return errorListData<ISiteState, ISite[]>({ ...state }, ['sites'], String(action.error.message))
      })

      .addCase(sitesService.fetchSiteSelectors.pending, (state) => {
        return requestListData<ISiteState, ISiteSelector[]>({ ...state }, ['siteSelectors'])
      })
      .addCase(sitesService.fetchSiteSelectors.fulfilled, (state, action) => {
        return receiveListData<ISiteState, ISiteSelector[]>({ ...state }, ['siteSelectors'], action.payload)
      })
      .addCase(sitesService.fetchSiteSelectors.rejected, (state, action) => {
        return errorListData<ISiteState, ISiteSelector[]>({ ...state }, ['siteSelectors'], String(action.error.message))
      })
      .addCase(sitesService.fetchSite.pending, (state) => {
        return requestListData<ISiteState, ISite>({ ...state }, ['site'])
      })
      .addCase(sitesService.fetchSite.fulfilled, (state, action) => {
        return receiveListData<ISiteState, ISite>({ ...state }, ['site'], action.payload)
      })
      .addCase(sitesService.fetchSite.rejected, (state, action) => {
        return errorListData<ISiteState, ISite>({ ...state }, ['site'], String(action.error.message))
      })

      .addCase(sitesService.fetchSiteMinInfo.pending, (state) => {
        return requestItemData<ISiteState, ISite>({ ...state }, ['siteMinInfo'])
      })
      .addCase(sitesService.fetchSiteMinInfo.fulfilled, (state, action) => {
        return receiveItemData<ISiteState, ISite>({ ...state }, ['siteMinInfo'], action.payload.site)
      })
      .addCase(sitesService.fetchSiteMinInfo.rejected, (state, action) => {
        return errorItemData<ISiteState, ISite>({ ...state }, ['siteMinInfo'], String(action.error.message))
      })

      .addCase(sitesService.fetchSiteSchedules.pending, (state) => {
        return requestListData<ISiteState, ISiteSchedule[]>({ ...state }, ['siteSchedules'])
      })
      .addCase(sitesService.fetchSiteSchedules.fulfilled, (state, action) => {
        return receiveListData<ISiteState, ISiteSchedule[]>({ ...state }, ['siteSchedules'], action.payload)
      })
      .addCase(sitesService.fetchSiteSchedules.rejected, (state, action) => {
        return errorListData<ISiteState, ISiteSchedule[]>({ ...state }, ['siteSchedules'], String(action.error.message))
      })

      .addCase(sitesService.deleteSite.pending, (state) => {
        return requestDeleteItem<ISiteState, ISite>({ ...state }, ['site'])
      })
      .addCase(sitesService.deleteSite.fulfilled, (state) => {
        return receiveDeleteItem<ISiteState, ISite>({ ...state }, ['site'])
      })
      .addCase(sitesService.deleteSite.rejected, (state, action) => {
        return errorDeleteItem<ISiteState, ISite>({ ...state }, ['site'], String(action.error.message))
      })
      .addCase(sitesService.updateSite.pending, (state) => {
        return requestPostPutItemData<ISiteState, ISite>({ ...state }, ['site'])
      })
      .addCase(sitesService.updateSite.fulfilled, (state, action) => {
        return receivePostPutItemData<ISiteState, ISite>({ ...state }, ['site'], action.payload)
      })
      .addCase(sitesService.updateSite.rejected, (state, action) => {
        return errorPostPutItemData<ISiteState, ISite>({ ...state }, ['site'], String(action.error.message))
      })

      /// Product Options
      .addCase(sitesService.fetchProductOptions.pending, (state) => {
        return requestListData<ISiteState, ISProducts[]>({ ...state }, ['productOptions'])
      })
      .addCase(sitesService.fetchProductOptions.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<ISiteState, ISProducts[]>({ ...state }, ['productOptions'], items, total_items)
      })
      .addCase(sitesService.fetchProductOptions.rejected, (state, action) => {
        return errorListData<ISiteState, ISProducts[]>({ ...state }, ['productOptions'], String(action.error.message))
      })
      .addCase(sitesService.fetchProductOptionsCreate.pending, (state) => {
        return requestListData<ISiteState, ISProductsCreate>({ ...state }, ['productOptionsCreate'])
      })
      .addCase(sitesService.fetchProductOptionsCreate.fulfilled, (state, action) => {
        return receiveListData<ISiteState, ISProductsCreate>({ ...state }, ['productOptionsCreate'], action.payload)
      })
      .addCase(sitesService.fetchProductOptionsCreate.rejected, (state, action) => {
        return errorListData<ISiteState, ISProductsCreate>(
          { ...state },
          ['productOptionsCreate'],
          String(action.error.message),
        )
      })

      .addCase(sitesService.fetchProductOption.pending, (state) => {
        return requestItemData<ISiteState, ISProducts>({ ...state }, ['productOption'])
      })
      .addCase(sitesService.fetchProductOption.fulfilled, (state, action) => {
        return receiveItemData<ISiteState, ISProducts>({ ...state }, ['productOption'], action.payload)
      })
      .addCase(sitesService.fetchProductOption.rejected, (state, action) => {
        return errorItemData<ISiteState, ISProducts>({ ...state }, ['productOption'], String(action.error.message))
      })

      .addCase(sitesService.deleteProductOption.pending, (state) => {
        return requestDeleteItem<ISiteState, ISProducts>({ ...state }, ['productOptionsDelete'])
      })
      .addCase(sitesService.deleteProductOption.fulfilled, (state) => {
        return receiveDeleteItem<ISiteState, ISProducts>({ ...state }, ['productOptionsDelete'])
      })
      .addCase(sitesService.deleteProductOption.rejected, (state, action) => {
        return errorDeleteItem<ISiteState, ISProducts>(
          { ...state },
          ['productOptionsDelete'],
          String(action.error.message),
        )
      })
      .addCase(sitesService.updateProductOption.pending, (state) => {
        return requestDeleteItem<ISiteState, ISProducts>({ ...state }, ['productOptionsUpdate'])
      })
      .addCase(sitesService.updateProductOption.fulfilled, (state) => {
        return receiveDeleteItem<ISiteState, ISProducts>({ ...state }, ['productOptionsUpdate'])
      })
      .addCase(sitesService.updateProductOption.rejected, (state, action) => {
        return errorDeleteItem<ISiteState, ISProducts>(
          { ...state },
          ['productOptionsUpdate'],
          String(action.error.message),
        )
      })

      /// Product Services

      .addCase(sitesService.fetchProductServices.pending, (state) => {
        return requestListData<ISiteState, ISProducts[]>({ ...state }, ['productServices'])
      })
      .addCase(sitesService.fetchProductServices.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<ISiteState, ISProducts[]>({ ...state }, ['productServices'], items, total_items)
      })
      .addCase(sitesService.fetchProductServices.rejected, (state, action) => {
        return errorListData<ISiteState, ISProducts[]>({ ...state }, ['productServices'], String(action.error.message))
      })
      .addCase(sitesService.fetchProductServicesCreate.pending, (state) => {
        return requestListData<ISiteState, ISProductsCreate>({ ...state }, ['productServicesCreate'])
      })
      .addCase(sitesService.fetchProductServicesCreate.fulfilled, (state, action) => {
        return receiveListData<ISiteState, ISProductsCreate>({ ...state }, ['productServicesCreate'], action.payload)
      })
      .addCase(sitesService.fetchProductServicesCreate.rejected, (state, action) => {
        return errorListData<ISiteState, ISProductsCreate>(
          { ...state },
          ['productServicesCreate'],
          String(action.error.message),
        )
      })

      .addCase(sitesService.fetchProductService.pending, (state) => {
        return requestItemData<ISiteState, ISProducts>({ ...state }, ['productService'])
      })
      .addCase(sitesService.fetchProductService.fulfilled, (state, action) => {
        return receiveItemData<ISiteState, ISProducts>({ ...state }, ['productService'], action.payload)
      })
      .addCase(sitesService.fetchProductService.rejected, (state, action) => {
        return errorItemData<ISiteState, ISProducts>({ ...state }, ['productService'], String(action.error.message))
      })

      .addCase(sitesService.deleteProductService.pending, (state) => {
        return requestDeleteItem<ISiteState, ISProducts>({ ...state }, ['productServiceDelete'])
      })
      .addCase(sitesService.deleteProductService.fulfilled, (state) => {
        return receiveDeleteItem<ISiteState, ISProducts>({ ...state }, ['productServiceDelete'])
      })
      .addCase(sitesService.deleteProductService.rejected, (state, action) => {
        return errorDeleteItem<ISiteState, ISProducts>(
          { ...state },
          ['productServiceDelete'],
          String(action.error.message),
        )
      })
      .addCase(sitesService.updateProductService.pending, (state) => {
        return requestDeleteItem<ISiteState, ISProducts>({ ...state }, ['productServiceUpdate'])
      })
      .addCase(sitesService.updateProductService.fulfilled, (state) => {
        return receiveDeleteItem<ISiteState, ISProducts>({ ...state }, ['productServiceUpdate'])
      })
      .addCase(sitesService.updateProductService.rejected, (state, action) => {
        return errorDeleteItem<ISiteState, ISProducts>(
          { ...state },
          ['updateProductService'],
          String(action.error.message),
        )
      })

      /// Inventory products

      .addCase(sitesService.fetchInventory.pending, (state) => {
        return requestListData<ISiteState, ISInventory[]>({ ...state }, ['inventoryProducts'])
      })
      .addCase(sitesService.fetchInventory.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<ISiteState, ISInventory[]>({ ...state }, ['inventoryProducts'], items, total_items)
      })
      .addCase(sitesService.fetchInventory.rejected, (state, action) => {
        return errorListData<ISiteState, ISInventory[]>(
          { ...state },
          ['inventoryProducts'],
          String(action.error.message),
        )
      })
      .addCase(sitesService.fetchInventoryDetails.pending, (state) => {
        return requestItemData<ISiteState, ISInventoryDetails>({ ...state }, ['inventoryProductsDetails'])
      })
      .addCase(sitesService.fetchInventoryDetails.fulfilled, (state, action) => {
        return receiveItemData<ISiteState, ISInventoryDetails>(
          { ...state },
          ['inventoryProductsDetails'],
          action.payload,
        )
      })
      .addCase(sitesService.fetchInventoryDetails.rejected, (state, action) => {
        return errorItemData<ISiteState, ISInventoryDetails>(
          { ...state },
          ['inventoryProductsDetails'],
          String(action.error.message),
        )
      })
      .addCase(sitesService.fetchInventoryCreate.pending, (state) => {
        return requestListData<ISiteState, ISProductsCreate>({ ...state }, ['inventoryProductsCreateData'])
      })
      .addCase(sitesService.fetchInventoryCreate.fulfilled, (state, action) => {
        return receiveListData<ISiteState, ISProductsCreate>(
          { ...state },
          ['inventoryProductsCreateData'],
          action.payload,
        )
      })
      .addCase(sitesService.fetchInventoryCreate.rejected, (state, action) => {
        return errorListData<ISiteState, ISProductsCreate>(
          { ...state },
          ['inventoryProductsCreateData'],
          String(action.error.message),
        )
      })
      .addCase(sitesService.deleteInventory.pending, (state) => {
        return requestDeleteItem<ISiteState, ISInventory>({ ...state }, ['inventoryProductsDelete'])
      })
      .addCase(sitesService.deleteInventory.fulfilled, (state) => {
        return receiveDeleteItem<ISiteState, ISInventory>({ ...state }, ['inventoryProductsDelete'])
      })
      .addCase(sitesService.deleteInventory.rejected, (state, action) => {
        return errorDeleteItem<ISiteState, ISInventory>(
          { ...state },
          ['inventoryProductsDelete'],
          String(action.error.message),
        )
      })

      .addCase(sitesService.createInventory.pending, (state) => {
        return requestItemData<ISiteState, ISInventoryItemUpdate>({ ...state }, ['createInventoryProducts'])
      })
      .addCase(sitesService.createInventory.fulfilled, (state, action) => {
        const { items } = action.payload
        return receiveItemData<ISiteState, ISInventoryItemUpdate>({ ...state }, ['createInventoryProducts'], items)
      })
      .addCase(sitesService.createInventory.rejected, (state, action) => {
        return errorItemData<ISiteState, ISInventoryItemUpdate>(
          { ...state },
          ['createInventoryProducts'],
          String(action.error.message),
        )
      })

      /// inventory packages

      .addCase(sitesService.fetchInventoryPackages.pending, (state) => {
        return requestListData<ISiteState, IPackages[]>({ ...state }, ['inventoryPackages'])
      })
      .addCase(sitesService.fetchInventoryPackages.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<ISiteState, IPackages[]>({ ...state }, ['inventoryPackages'], items, total_items)
      })
      .addCase(sitesService.fetchInventoryPackages.rejected, (state, action) => {
        return errorListData<ISiteState, IPackages[]>({ ...state }, ['inventoryPackages'], String(action.error.message))
      })
      .addCase(sitesService.fetchInventoryPackage.pending, (state) => {
        return requestItemData<ISiteState, IPackage>({ ...state }, ['inventoryPackage'])
      })
      .addCase(sitesService.fetchInventoryPackage.fulfilled, (state, action) => {
        return receiveItemData<ISiteState, IPackage>({ ...state }, ['inventoryPackage'], action.payload)
      })
      .addCase(sitesService.fetchInventoryPackage.rejected, (state, action) => {
        return errorItemData<ISiteState, IPackage>({ ...state }, ['inventoryPackage'], String(action.error.message))
      })
      .addCase(sitesService.fetchInventoryPackagesCreate.pending, (state) => {
        return requestListData<ISiteState, ISProductsCreate>({ ...state }, ['inventoryPackagesCreate'])
      })
      .addCase(sitesService.fetchInventoryPackagesCreate.fulfilled, (state, action) => {
        return receiveListData<ISiteState, ISProductsCreate>({ ...state }, ['inventoryPackagesCreate'], action.payload)
      })
      .addCase(sitesService.fetchInventoryPackagesCreate.rejected, (state, action) => {
        return errorListData<ISiteState, ISProductsCreate>(
          { ...state },
          ['inventoryPackagesCreate'],
          String(action.error.message),
        )
      })

      .addCase(sitesService.updateInventoryPackage.pending, (state) => {
        return requestPostPutItemData<ISiteState, IPackage>({ ...state }, ['inventoryPackageUpdate'])
      })
      .addCase(sitesService.updateInventoryPackage.fulfilled, (state, action) => {
        return receivePostPutItemData<ISiteState, IPackage>({ ...state }, ['inventoryPackageUpdate'], action.payload)
      })
      .addCase(sitesService.updateInventoryPackage.rejected, (state, action) => {
        return errorPostPutItemData<ISiteState, IPackage>(
          { ...state },
          ['inventoryPackageUpdate'],
          String(action.error.message),
        )
      })

      .addCase(sitesService.addInventoryPackage.pending, (state) => {
        return requestListData<ISiteState, IPackageAdd>({ ...state }, ['inventoryPackageAdd'])
      })
      .addCase(sitesService.addInventoryPackage.fulfilled, (state, action) => {
        return receiveListData<ISiteState, IPackageAdd>({ ...state }, ['inventoryPackageAdd'], action.payload)
      })
      .addCase(sitesService.addInventoryPackage.rejected, (state, action) => {
        return errorListData<ISiteState, IPackageAdd>(
          { ...state },
          ['inventoryPackageAdd'],
          String(action.error.message),
        )
      })
      .addCase(sitesService.deleteInventoryPackage.pending, (state) => {
        return requestDeleteItem<ISiteState, ISInventory>({ ...state }, ['inventoryPackageDelete'])
      })
      .addCase(sitesService.deleteInventoryPackage.fulfilled, (state) => {
        return receiveDeleteItem<ISiteState, ISInventory>({ ...state }, ['inventoryPackageDelete'])
      })
      .addCase(sitesService.deleteInventoryPackage.rejected, (state, action) => {
        return errorDeleteItem<ISiteState, ISInventory>(
          { ...state },
          ['inventoryPackageDelete'],
          String(action.error.message),
        )
      })

      /// inventory packages
      .addCase(sitesService.fetchStockMovements.pending, (state) => {
        return requestListData<ISiteState, IStockMovement[]>({ ...state }, ['stockMovements'])
      })
      .addCase(sitesService.fetchStockMovements.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<ISiteState, IStockMovement[]>({ ...state }, ['stockMovements'], items, total_items)
      })
      .addCase(sitesService.fetchStockMovements.rejected, (state, action) => {
        return errorListData<ISiteState, IStockMovement[]>(
          { ...state },
          ['stockMovements'],
          String(action.error.message),
        )
      })

      .addCase(sitesService.addStockMovements.pending, (state) => {
        return requestListData<ISiteState, IStockMovement[]>({ ...state }, ['stockMovementsAdd'])
      })
      .addCase(sitesService.addStockMovements.fulfilled, (state, action) => {
        const { stock_mover, inventory } = action.payload

        return receiveListData<ISiteState, IStockMovement>(
          {
            ...state,
            inventoryProductsDetails: {
              ...state.inventoryProductsDetails,
              data: {
                ...state.inventoryProductsDetails.data!,
                item: { ...state.inventoryProductsDetails.data!.item, inventory },
              },
            },
          },
          ['stockMovementsAdd'],
          stock_mover,
        )
      })
      .addCase(sitesService.addStockMovements.rejected, (state, action) => {
        return errorListData<ISiteState, IStockMovement>(
          { ...state },
          ['stockMovementsAdd'],
          String(action.error.message),
        )
      })

      // Customers

      .addCase(sitesService.fetchPendingCustomers.pending, (state) => {
        return requestListData<ISiteState, IPendingCustomers>({ ...state }, ['pendingCustomers'])
      })
      .addCase(sitesService.fetchPendingCustomers.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<ISiteState, IPendingCustomers>({ ...state }, ['pendingCustomers'], items, total_items)
      })
      .addCase(sitesService.fetchPendingCustomers.rejected, (state, action) => {
        return errorListData<ISiteState, IPendingCustomers>(
          { ...state },
          ['pendingCustomers'],
          String(action.error.message),
        )
      })

      .addCase(sitesService.fetchCurrentCustomers.pending, (state) => {
        return requestListData<ISiteState, ICurrentCustomers>({ ...state }, ['currentCustomers'])
      })
      .addCase(sitesService.fetchCurrentCustomers.fulfilled, (state, action) => {
        const { total_items, ...rest } = action.payload
        return receiveListData<ISiteState, ICurrentCustomers>(
          { ...state },
          ['currentCustomers'],
          total_items ? rest : [],
          total_items,
        )
      })
      .addCase(sitesService.fetchCurrentCustomers.rejected, (state, action) => {
        return errorListData<ISiteState, ICurrentCustomers>(
          { ...state },
          ['currentCustomers'],
          String(action.error.message),
        )
      })
  },
})

export const { storeSelectedSite, resetSliceError } = sitesSlice.actions

export default sitesSlice.reducer
