import { createSlice } from '@reduxjs/toolkit'

import {
  errorItemData,
  errorListData,
  errorPostPutItemData,
  receiveItemData,
  receiveListData,
  receivePostPutItemData,
  requestItemData,
  requestListData,
  requestPostPutItemData,
} from '@/common/utils/state.utils'

import { ISiteSchedule, ISiteLeaseDay } from '@/features/sites/interfaces/site-building-schedule.interface'
import { siteSchedulesService } from '@/features/sites/state/schedules/siteSchedules.service'
import { IScheduleState, ScheduleState } from '@/features/sites/state/schedules/siteSchedules.state.interface'

const initialState = new ScheduleState()

const siteSchedulesSlice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(siteSchedulesService.fetchSiteSchedules.pending, (state) => {
        return requestListData<IScheduleState, ISiteSchedule[]>({ ...state }, ['schedules'])
      })
      .addCase(siteSchedulesService.fetchSiteSchedules.fulfilled, (state, action) => {
        return receiveListData<IScheduleState, ISiteSchedule[]>({ ...state }, ['schedules'], action.payload)
      })
      .addCase(siteSchedulesService.fetchSiteSchedules.rejected, (state, action) => {
        return errorListData<IScheduleState, ISiteSchedule[]>({ ...state }, ['schedules'], String(action.error.message))
      })

      .addCase(siteSchedulesService.addSiteSchedule.pending, (state) => {
        return requestListData<IScheduleState, ISiteSchedule>({ ...state }, ['schedule'])
      })
      .addCase(siteSchedulesService.addSiteSchedule.fulfilled, (state, action) => {
        return receiveListData<IScheduleState, ISiteSchedule>({ ...state }, ['schedule'], action.payload)
      })
      .addCase(siteSchedulesService.addSiteSchedule.rejected, (state, action) => {
        return errorListData<IScheduleState, ISiteSchedule>({ ...state }, ['schedule'], String(action.error.message))
      })
      .addCase(siteSchedulesService.updateSiteSchedule.pending, (state) => {
        return requestPostPutItemData<IScheduleState, ISiteSchedule>({ ...state }, ['schedule'])
      })
      .addCase(siteSchedulesService.updateSiteSchedule.fulfilled, (state, action) => {
        return receivePostPutItemData<IScheduleState, ISiteSchedule>({ ...state }, ['schedule'], action.payload)
      })
      .addCase(siteSchedulesService.updateSiteSchedule.rejected, (state, action) => {
        return errorPostPutItemData<IScheduleState, ISiteSchedule>(
          { ...state },
          ['schedule'],
          String(action.error.message),
        )
      })
      /// lease day
      .addCase(siteSchedulesService.fetchLeaseDay.pending, (state) => {
        return requestItemData<IScheduleState, ISiteLeaseDay[]>({ ...state }, ['scheduleLeaseDays'])
      })
      .addCase(siteSchedulesService.fetchLeaseDay.fulfilled, (state, action) => {
        return receiveItemData<IScheduleState, ISiteLeaseDay[]>({ ...state }, ['scheduleLeaseDays'], action.payload)
      })
      .addCase(siteSchedulesService.fetchLeaseDay.rejected, (state, action) => {
        return errorItemData<IScheduleState, ISiteLeaseDay[]>(
          { ...state },
          ['scheduleLeaseDays'],
          String(action.error.message),
        )
      })
  },
})

export default siteSchedulesSlice.reducer
