import { ICategories } from '@/common/interfaces/categories.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

export interface ICategoriesState {
  categories: IStoreApiList<ICategories>
}

export class CategoriesState implements ICategoriesState {
  categories: IStoreApiList<ICategories> = new StoreApiList()
}
