import { createAsyncThunk } from '@reduxjs/toolkit'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'

const baseUrl = `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/terms-conditions`

const fetchCondition = createAsyncThunk('condition/fetchCondition', async () => {
  const response = await api(HttpMethods.GET, `${baseUrl}`, {})
  return response.data
})

const updateCondition = createAsyncThunk('condition/updateCondition', async ({ data }: { data: string }) => {
  const response = await api(HttpMethods.POST, `${baseUrl}`, { content: data })
  return response.data
})

export const conditionService = {
  fetchCondition,
  updateCondition,
}
