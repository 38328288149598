import { createAsyncThunk } from '@reduxjs/toolkit'
import type { TablePaginationConfig } from 'antd/es/table'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'
import { buildPaginationQuery } from '@/common/utils/table.utils'

import {
  IPackageAdd,
  ISInventoryItemUpdate,
  ISite,
  ISProductUpdate,
  IStockAdd,
} from '@/features/sites/interfaces/site.interface'

const fetchSiteSelectors = createAsyncThunk('sites/fetchSitesSelectors', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/site-selector`, {})
  return response.data
})

const fetchSiteMinInfo = createAsyncThunk('sites/fetchSiteMinInfo', async (selectedSite: string) => {
  const response = await api(
    HttpMethods.GET,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/site/min-info/${selectedSite}`,
  )
  return response.data
})

const fetchSites = createAsyncThunk('sites/fetchSites', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/sites`, {})
  return response.data
})

const fetchSite = createAsyncThunk('sites/fetchSite', async (siteSelector: string | undefined) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/site/${siteSelector}`, {})
  return response.data
})

const fetchSiteSchedules = createAsyncThunk('sites/fetchSiteSchedules', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/schedule`, {})
  return response.data.schedules
})

const updateSite = createAsyncThunk('sites/updateSite', async (data: ISite) => {
  const response = await api(HttpMethods.PUT, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/sites/${data.id}`, data)
  return response.data
})

const deleteSite = createAsyncThunk('sites/deleteSite', async (id: number) => {
  const response = await api(HttpMethods.DELETE, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/sites/${id}`, {})
  return response.data
})

/// Product Options

const fetchProductOptions = createAsyncThunk('sites/productOptions', async (tableParams: TablePaginationConfig) => {
  const response = await api(
    HttpMethods.GET,
    buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/productoptions`, tableParams),
    {},
  )
  return response.data
})

const fetchProductOptionsCreate = createAsyncThunk('sites/productOptionsCreate', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/productoptions/create`, {})
  return response.data
})

const fetchProductOption = createAsyncThunk('sites/productOption', async (id: number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/productoptions/${id}`, {})
  return response.data
})

const deleteProductOption = createAsyncThunk('sites/deletedProductOption', async (id: number) => {
  const response = await api(
    HttpMethods.DELETE,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/productoptions/${id}`,
    {},
  )
  return response.data
})

const updateProductOption = createAsyncThunk(
  'sites/updateProductionOption',
  async ({ id, ...rest }: ISProductUpdate) => {
    const response = await api(HttpMethods.PUT, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/productoptions/${id}`, {
      ...rest,
    })
    return response.data
  },
)

const createProductOption = createAsyncThunk(
  'sites/createProductionOption',
  async ({ id, ...rest }: ISProductUpdate) => {
    const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/productoptions`, {
      ...rest,
      option_type_id: id,
    })
    return response.data
  },
)

/// Product Services

const fetchProductServices = createAsyncThunk('sites/productServices', async (tableParams: TablePaginationConfig) => {
  const response = await api(
    HttpMethods.GET,
    buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/productservices`, tableParams),
  )
  return response.data
})

const fetchProductServicesCreate = createAsyncThunk('sites/productServicesCreate', async () => {
  const response = await api(
    HttpMethods.GET,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/productservices/create`,
    {},
  )
  return response.data
})

const fetchProductService = createAsyncThunk('sites/productService', async (id: number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/productservices/${id}`, {})
  return response.data
})

const deleteProductService = createAsyncThunk('sites/deleteProductService', async (id: number) => {
  const response = await api(HttpMethods.DELETE, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/productservices/${id}`)
  return response.data
})

const updateProductService = createAsyncThunk('sites/updateProductionService', async (data: ISProductUpdate) => {
  const response = await api(
    HttpMethods.PUT,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/productservices/${data.id}`,
    {
      room_type_ids: data.room_type_ids,
    },
  )
  return response.data
})

const createProductService = createAsyncThunk('sites/createProductService', async (data: ISProductUpdate) => {
  const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/productservices`, {
    room_type_ids: data.room_type_ids,
    option_type_id: data.id,
  })
  return response.data
})

/// Inventory products

const fetchInventory = createAsyncThunk('sites/inventory', async (tableParams: TablePaginationConfig) => {
  const response = await api(
    HttpMethods.GET,
    buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/inventory`, tableParams),
    {},
  )
  return response.data
})

const fetchInventoryCreate = createAsyncThunk('sites/fetchInventoryCreate', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/inventory/create`, {})
  return response.data
})

const fetchInventoryDetails = createAsyncThunk('sites/inventoryDetails', async (id: number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/inventory/${id}`, {})
  return response.data
})

const deleteInventory = createAsyncThunk('sites/deleteInventory', async (id: number) => {
  const response = await api(HttpMethods.DELETE, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/inventory/${id}`, {})
  return response.data
})

const createInventory = createAsyncThunk('sites/createInventory', async (data: ISInventoryItemUpdate) => {
  const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/inventory`, {
    ...data,
  })
  return response.data
})
const updateInventory = createAsyncThunk(
  'sites/inventoryUpdate',
  async ({ id, data }: { id: number; data: ISInventoryItemUpdate }) => {
    const response = await api(HttpMethods.PUT, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/inventory/${id}`, {
      ...data,
    })
    return response.data
  },
)

/// Inventory packages

const fetchInventoryPackages = createAsyncThunk(
  'sites/inventoryPackages',
  async (tableParams: TablePaginationConfig) => {
    const response = await api(
      HttpMethods.GET,
      buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/packages`, tableParams),
      {},
    )
    return response.data
  },
)

const fetchInventoryPackagesCreate = createAsyncThunk('sites/inventoryPackagesCreate', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/packages/create`, {})
  return response.data
})

const fetchInventoryPackage = createAsyncThunk('sites/inventoryPackage', async (id: number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/packages/${id}`, {})
  return response.data
})

const deleteInventoryPackage = createAsyncThunk('sites/deleteInventoryPackage', async (id: number) => {
  const response = await api(HttpMethods.DELETE, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/packages/${id}`, {})
  return response.data
})

const updateInventoryPackage = createAsyncThunk(
  'sites/inventoryPackageUpdate',
  async ({ id, data }: { id: number; data: IPackageAdd }) => {
    const response = await api(HttpMethods.PUT, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/packages/${id}`, data)
    return response.data
  },
)

const addInventoryPackage = createAsyncThunk('sites/inventoryPackageAdd', async (data: IPackageAdd) => {
  const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/packages`, data)
  return response.data
})

/// Stock movements

const fetchStockMovements = createAsyncThunk(
  'sites/stockMovements',
  async (payload: { id: number; tableParams: TablePaginationConfig }) => {
    const response = await api(
      HttpMethods.GET,
      buildPaginationQuery(
        `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/inventory/${payload.id}/stock_mover`,
        payload.tableParams,
      ),
    )
    return response.data
  },
)

const addStockMovements = createAsyncThunk(
  'sites/stockMovementsAdd',
  async ({ id, data }: { id: number; data: IStockAdd }) => {
    const response = await api(
      HttpMethods.POST,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/inventory/${id}/stock_mover`,
      data,
    )
    return response.data
  },
)
const deleteStockMovements = createAsyncThunk('sites/stockMovementsDelete', async (id: number) => {
  const response = await api(
    HttpMethods.DELETE,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/inventory/${id}/stock_mover`,
  )
  return response.data
})

const updateStockMovements = createAsyncThunk(
  'sites/stockMovementsUpdate',
  async ({
    id,
    data,
    stock_mov_id,
  }: {
    id: number
    data: { status: number; quantity?: number }
    stock_mov_id: number
  }) => {
    const response = await api(
      HttpMethods.PUT,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/inventory/${id}/stock_mover/${stock_mov_id}`,
      data,
    )
    return response.data
  },
)

// customers

const fetchPendingCustomers = createAsyncThunk(
  'sites/pendingCustomers',
  async ({
    tableParams,
    data,
  }: {
    tableParams: TablePaginationConfig
    data: { start_date: string; end_date: string }
  }) => {
    const response = await api(
      HttpMethods.GET,
      buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/customers/pending`, tableParams),
      {
        ...data,
      },
    )
    return response.data
  },
)

const fetchCurrentCustomers = createAsyncThunk('sites/currentCustomers', async (tableParams: TablePaginationConfig) => {
  const response = await api(
    HttpMethods.GET,
    buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/customers/current`, tableParams),
  )
  return response.data
})

export const sitesService = {
  fetchSites,
  fetchSiteSelectors,
  fetchSite,
  fetchSiteSchedules,
  updateSite,
  deleteSite,
  fetchProductOptions,
  fetchProductOption,
  deleteProductOption,
  updateProductOption,
  createProductOption,
  fetchProductOptionsCreate,
  fetchProductServices,
  fetchProductServicesCreate,
  fetchProductService,
  updateProductService,
  createProductService,
  deleteProductService,
  fetchInventory,
  fetchInventoryCreate,
  fetchInventoryDetails,
  deleteInventory,
  updateInventory,
  createInventory,
  fetchInventoryPackages,
  fetchInventoryPackagesCreate,
  fetchInventoryPackage,
  deleteInventoryPackage,
  updateInventoryPackage,
  addInventoryPackage,
  fetchStockMovements,
  addStockMovements,
  deleteStockMovements,
  updateStockMovements,
  fetchPendingCustomers,
  fetchCurrentCustomers,
  fetchSiteMinInfo,
}
