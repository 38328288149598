import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  errorDeleteItem,
  errorItemData,
  errorListData,
  errorPostPutItemData,
  receiveDeleteItem,
  receiveItemData,
  receiveListData,
  receivePostPutItemData,
  requestDeleteItem,
  requestItemData,
  requestListData,
  requestPostPutItemData,
} from '@/common/utils/state.utils'

import { IAmbientMusic, IMarketingImages } from 'src/features/media/interfaces/media.interface'

import { mediaService } from './media.service'
import { MediaState } from './media.state.interface'

const initialState = new MediaState()

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    updateStreamingMusic: (state, action: PayloadAction<IAmbientMusic[]>) => {
      state.marketingMusic.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      ///marketing images
      .addCase(mediaService.fetchMarketingImages.pending, (state) => {
        return requestItemData<MediaState, IMarketingImages>({ ...state }, ['marketingImages'])
      })
      .addCase(mediaService.fetchMarketingImages.fulfilled, (state, action) => {
        return receiveItemData<MediaState, IMarketingImages>({ ...state }, ['marketingImages'], action.payload)
      })
      .addCase(mediaService.fetchMarketingImages.rejected, (state, action) => {
        return errorItemData<MediaState, IMarketingImages>(
          { ...state },
          ['marketingImages'],
          String(action.error.message),
        )
      })

      ///add image
      .addCase(mediaService.uploadMarketingImage.pending, (state) => {
        return requestPostPutItemData<MediaState, IMarketingImages>({ ...state }, ['marketingAddImage'])
      })
      .addCase(mediaService.uploadMarketingImage.fulfilled, (state, action) => {
        return receivePostPutItemData<MediaState, IMarketingImages>({ ...state }, ['marketingAddImage'], action.payload)
      })
      .addCase(mediaService.uploadMarketingImage.rejected, (state, action) => {
        return errorPostPutItemData<MediaState, IMarketingImages>(
          { ...state },
          ['marketingAddImage'],
          String(action.error.message),
        )
      })

      ///delete image
      .addCase(mediaService.deleteMarketingImage.pending, (state) => {
        return requestDeleteItem<MediaState, IMarketingImages>({ ...state }, ['marketingDeleteImage'])
      })
      .addCase(mediaService.deleteMarketingImage.fulfilled, (state, action) => {
        return receiveDeleteItem<MediaState, IMarketingImages>({ ...state }, ['marketingDeleteImage'])
      })
      .addCase(mediaService.deleteMarketingImage.rejected, (state, action) => {
        return errorDeleteItem<MediaState, IMarketingImages>(
          { ...state },
          ['marketingDeleteImage'],
          String(action.error.message),
        )
      })

      /// marketing music
      .addCase(mediaService.fetchMarketingMusic.pending, (state) => {
        return requestListData<MediaState, IAmbientMusic[]>({ ...state }, ['marketingMusic'])
      })
      .addCase(mediaService.fetchMarketingMusic.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<MediaState, IAmbientMusic[]>({ ...state }, ['marketingMusic'], items, total_items)
      })
      .addCase(mediaService.fetchMarketingMusic.rejected, (state, action) => {
        return errorListData<MediaState, IAmbientMusic[]>(
          { ...state },
          ['marketingMusic'],
          String(action.error.message),
        )
      })

      ///delete music
      .addCase(mediaService.deleteMarketingMusic.pending, (state) => {
        return requestDeleteItem<MediaState, null>({ ...state }, ['marketingDeleteMusic'])
      })
      .addCase(mediaService.deleteMarketingMusic.fulfilled, (state, action) => {
        return receiveDeleteItem<MediaState, null>({ ...state }, ['marketingDeleteMusic'])
      })
      .addCase(mediaService.deleteMarketingMusic.rejected, (state, action) => {
        return errorDeleteItem<MediaState, null>({ ...state }, ['marketingDeleteMusic'], String(action.error.message))
      })

      ///add music
      .addCase(mediaService.addMarketingMusic.pending, (state) => {
        return requestPostPutItemData<MediaState, null>({ ...state }, ['marketingAddMusic'])
      })
      .addCase(mediaService.addMarketingMusic.fulfilled, (state, action) => {
        return receivePostPutItemData<MediaState, null>({ ...state }, ['marketingAddMusic'], action.payload)
      })
      .addCase(mediaService.addMarketingMusic.rejected, (state, action) => {
        return errorPostPutItemData<MediaState, null>({ ...state }, ['marketingAddMusic'], String(action.error.message))
      })

      ///add music
      .addCase(mediaService.updateMarketingMusic.pending, (state) => {
        return requestPostPutItemData<MediaState, null>({ ...state }, ['marketingUpdateMusic'])
      })
      .addCase(mediaService.updateMarketingMusic.fulfilled, (state, action) => {
        return receivePostPutItemData<MediaState, null>({ ...state }, ['marketingUpdateMusic'], action.payload)
      })
      .addCase(mediaService.updateMarketingMusic.rejected, (state, action) => {
        return errorPostPutItemData<MediaState, null>(
          { ...state },
          ['marketingUpdateMusic'],
          String(action.error.message),
        )
      })
  },
})
export const { updateStreamingMusic } = mediaSlice.actions

export default mediaSlice.reducer
