import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import {
  errorDeleteItem,
  errorItemData,
  errorListData,
  errorPostPutItemData,
  receiveDeleteItem,
  receiveItemData,
  receiveListData,
  receivePostPutItemData,
  requestDeleteItem,
  requestItemData,
  requestListData,
  requestPostPutItemData,
} from '@/common/utils/state.utils'

import { IDictionaries, IDictionary } from 'src/features/terms/interfaces/terms.interface'

import { dictionaryService } from './dictionary.service'
import { DictionaryState, IDictionaryState } from './dictionary.state.interface'

const initialState = new DictionaryState()

const dictionarySlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {
    updateDictionaries: (state, action: PayloadAction<IDictionary[]>) => {
      state.dictionaries.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(dictionaryService.fetchDictionaries.pending, (state) => {
        return requestListData<IDictionaryState, IDictionaries>({ ...state }, ['dictionaries'])
      })
      .addCase(dictionaryService.fetchDictionaries.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<IDictionaryState, IDictionaries>({ ...state }, ['dictionaries'], items, total_items)
      })
      .addCase(dictionaryService.fetchDictionaries.rejected, (state, action) => {
        return errorListData<IDictionaryState, IDictionaries>(
          { ...state },
          ['dictionaries'],
          String(action.error.message),
        )
      })
      .addCase(dictionaryService.fetchDictionary.pending, (state) => {
        return requestItemData<IDictionaryState, IDictionary>({ ...state }, ['dictionary'])
      })
      .addCase(dictionaryService.fetchDictionary.fulfilled, (state, action) => {
        return receiveItemData<IDictionaryState, IDictionary>({ ...state }, ['dictionary'], action.payload.item)
      })
      .addCase(dictionaryService.fetchDictionary.rejected, (state, action) => {
        return errorItemData<IDictionaryState, IDictionary>({ ...state }, ['dictionary'], String(action.error.message))
      })
      .addCase(dictionaryService.createDictionary.pending, (state) => {
        return requestPostPutItemData<IDictionaryState, IDictionary>({ ...state }, ['dictionaryCreate'])
      })
      .addCase(dictionaryService.createDictionary.fulfilled, (state, action) => {
        return receivePostPutItemData<IDictionaryState, IDictionary>({ ...state }, ['dictionaryCreate'], action.payload)
      })
      .addCase(dictionaryService.createDictionary.rejected, (state, action) => {
        return errorPostPutItemData<IDictionaryState, IDictionary>(
          { ...state },
          ['dictionaryCreate'],
          String(action.error.message),
        )
      })
      .addCase(dictionaryService.updateDictionary.pending, (state) => {
        return requestPostPutItemData<IDictionaryState, IDictionary>({ ...state }, ['dictionaryUpdate'])
      })
      .addCase(dictionaryService.updateDictionary.fulfilled, (state, action) => {
        return receivePostPutItemData<IDictionaryState, IDictionary>({ ...state }, ['dictionaryUpdate'], action.payload)
      })
      .addCase(dictionaryService.updateDictionary.rejected, (state, action) => {
        return errorPostPutItemData<IDictionaryState, IDictionary>(
          { ...state },
          ['dictionaryUpdate'],
          String(action.error.message),
        )
      })
      .addCase(dictionaryService.deleteDictionary.pending, (state) => {
        return requestDeleteItem<IDictionaryState, IDictionary>({ ...state }, ['dictionaryDelete'])
      })
      .addCase(dictionaryService.deleteDictionary.fulfilled, (state) => {
        return receiveDeleteItem<IDictionaryState, IDictionary>({ ...state }, ['dictionaryDelete'])
      })
      .addCase(dictionaryService.deleteDictionary.rejected, (state, action) => {
        return errorDeleteItem<IDictionaryState, IDictionary>(
          { ...state },
          ['dictionaryDelete'],
          String(action.error.message),
        )
      })
  },
})

export const { updateDictionaries } = dictionarySlice.actions

export default dictionarySlice.reducer
