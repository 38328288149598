import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import {
  IGeneratePromotion,
  IPromotion,
  IPromotionConfigData,
} from '@/features/promotions/interfaces/promotions.interfaces'

export interface IPromotionsState {
  promotions: IStoreApiList<IPromotion[]>
  configData: IStoreApiItem<IPromotionConfigData>
  promotion: IStoreApiItem<IPromotion>
  generatedPromotion: IStoreApiItem<IGeneratePromotion>
}

export class PromotionsState implements IPromotionsState {
  promotions: IStoreApiList<IPromotion[]> = new StoreApiList<IPromotion[]>()
  configData: IStoreApiItem<IPromotionConfigData> = new StoreApiItem<IPromotionConfigData>()
  promotion: IStoreApiItem<IPromotion> = new StoreApiItem<IPromotion>()
  generatedPromotion: IStoreApiItem<IGeneratePromotion> = new StoreApiItem<IGeneratePromotion>()
}
