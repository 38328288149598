import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import { IDictionary } from 'src/features/terms/interfaces/terms.interface'

export interface IDictionaryState {
  dictionaries: IStoreApiList<IDictionary[]>
  dictionary: IStoreApiItem<IDictionary>
  dictionaryUpdate: IStoreApiItem<IDictionary>
  dictionaryDelete: IStoreApiItem<IDictionary>
  dictionaryCreate: IStoreApiItem<IDictionary>
}

export class DictionaryState implements IDictionaryState {
  dictionaries: IStoreApiList<IDictionary[]> = new StoreApiList()
  dictionary: IStoreApiItem<IDictionary> = new StoreApiItem()
  dictionaryUpdate: IStoreApiItem<IDictionary> = new StoreApiItem()
  dictionaryDelete: IStoreApiItem<IDictionary> = new StoreApiItem()
  dictionaryCreate: IStoreApiItem<IDictionary> = new StoreApiItem()
}
