import { IBuildingHour } from '@/features/sites/interfaces/site-building-schedule.interface'

export const Hours = [
  {
    value: '00:00',
    name: '00:00AM',
  },
  {
    value: '01:00',
    name: '01:00AM',
  },
  {
    value: '02:00',
    name: '02:00AM',
  },
  {
    value: '03:00',
    name: '03:00AM',
  },
  {
    value: '04:00',
    name: '04:00AM',
  },
  {
    value: '05:00',
    name: '05:00AM',
  },
  {
    value: '06:00',
    name: '06:00AM',
  },
  {
    value: '07:00',
    name: '07:00AM',
  },
  {
    value: '08:00',
    name: '08:00AM',
  },
  {
    value: '09:00',
    name: '09:00AM',
  },
  {
    value: '10:00',
    name: '10:00AM',
  },
  {
    value: '11:00',
    name: '11:00AM',
  },
  {
    value: '12:00',
    name: '12:00PM',
  },
  {
    value: '13:00',
    name: '01:00PM',
  },
  {
    value: '14:00',
    name: '02:00PM',
  },
  {
    value: '15:00',
    name: '03:00PM',
  },
  {
    value: '16:00',
    name: '04:00PM',
  },
  {
    value: '17:00',
    name: '05:00PM',
  },
  {
    value: '18:00',
    name: '06:00PM',
  },
  {
    value: '19:00',
    name: '07:00PM',
  },
  {
    value: '20:00',
    name: '08:00PM',
  },
  {
    value: '21:00',
    name: '09:00PM',
  },
  {
    value: '22:00',
    name: '10:00PM',
  },
  {
    value: '23:00',
    name: '11:00PM',
  },
]

export const Slots = [
  {
    value: 60,
    name: '1h time slots',
  },
  {
    value: 90,
    name: '1.5h time slots',
  },
  {
    value: 120,
    name: '2h time slots',
  },
  {
    value: 150,
    name: '2.5h time slots',
  },
  {
    value: 180,
    name: '3h time slots',
  },
]

export const NewHoursSchedule: IBuildingHour[] = [
  {
    id: 1,
    day_of_week: 0,
    start_time: '08:00',
    end_time: '20:00',
    is_open: 1,
    time_slot: 60,
  },
  {
    id: 2,
    day_of_week: 1,
    start_time: '08:00',
    end_time: '20:00',
    is_open: 1,
    time_slot: 60,
  },
  {
    id: 3,
    day_of_week: 2,
    start_time: '08:00',
    end_time: '20:00',
    is_open: 1,
    time_slot: 60,
  },
  {
    id: 4,
    day_of_week: 3,
    start_time: '08:00',
    end_time: '20:00',
    is_open: 1,
    time_slot: 60,
  },
  {
    id: 5,
    day_of_week: 4,
    start_time: '08:00',
    end_time: '20:00',
    is_open: 1,
    time_slot: 60,
  },
  {
    id: 6,
    day_of_week: 5,
    start_time: '08:00',
    end_time: '20:00',
    is_open: 1,
    time_slot: 60,
  },
  {
    id: 7,
    day_of_week: 6,
    start_time: '08:00',
    end_time: '20:00',
    is_open: 1,
    time_slot: 60,
  },
]
