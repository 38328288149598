import { createAsyncThunk } from '@reduxjs/toolkit'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'

import { IAmbientMusicPostPut } from 'src/features/media/interfaces/media.interface'

const fetchMarketingImages = createAsyncThunk('media/fetchMarketingImages', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/media-marketing/images`)

  return response.data
})

const uploadMarketingImage = createAsyncThunk('media/uploadMarketingImage', async (formData: FormData) => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  }

  const response = await api(
    HttpMethods.POST,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/media-marketing/images`,
    formData,
    config,
  )

  return response.data
})

const deleteMarketingImage = createAsyncThunk('media/deleteMarketingImage', async (id: string | number) => {
  const response = await api(
    HttpMethods.DELETE,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/media-marketing/images/${id}`,
  )
  return response.data
})

const fetchMarketingMusic = createAsyncThunk('media/fetchMusic', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/media-marketing/music`)
  return response.data
})

const updateMarketingMusic = createAsyncThunk(
  'media/updateMarketingMusic',
  async ({ id, data }: { id: string | number; data: IAmbientMusicPostPut }) => {
    const response = await api(
      HttpMethods.PUT,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/media-marketing/music/${id}`,
      data,
    )
    return response.data
  },
)

const addMarketingMusic = createAsyncThunk('media/addMarketingMusic', async (data: IAmbientMusicPostPut) => {
  const response = await api(
    HttpMethods.POST,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/media-marketing/music`,
    data,
  )
  return response.data
})

const deleteMarketingMusic = createAsyncThunk('media/deleteMarketingMusic', async (id: string | number) => {
  const response = await api(
    HttpMethods.DELETE,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/media-marketing/music/${id}`,
  )
  return response.data
})

export const mediaService = {
  fetchMarketingImages,
  uploadMarketingImage,
  deleteMarketingImage,
  fetchMarketingMusic,
  deleteMarketingMusic,
  updateMarketingMusic,
  addMarketingMusic,
}
