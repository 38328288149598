import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import { IRoom } from '@/features/rooms/interfaces/room.interface'

export interface IRoomState {
  rooms: IStoreApiList<IRoom[]>
  room: IStoreApiItem<IRoom>
  roomAdded: IStoreApiItem<IRoom>
  roomUpdated: IStoreApiItem<IRoom>
  roomDeleted: IStoreApiItem<IRoom>
}

export class RoomState implements IRoomState {
  rooms: IStoreApiList<IRoom[]> = new StoreApiList()
  room: IStoreApiItem<IRoom> = new StoreApiItem()
  roomAdded: IStoreApiItem<IRoom> = new StoreApiItem()
  roomUpdated: IStoreApiItem<IRoom> = new StoreApiItem()
  roomDeleted: IStoreApiItem<IRoom> = new StoreApiItem()
}
