export enum InputTypes {
  TEXT = 'text',
  NUMBER = 'number',
  EMAIL = 'email',
  DATE = 'date',
  PASSWORD = 'password',
}

export enum FormControlTypes {
  RADIO = 'radio',
  INPUT = 'input',
  SELECT = 'select',
  SWITCH = 'switch',
  CHECKBOX = 'checkbox',
  TEXTAREA = 'textarea',
  UPLOAD = 'upload',
  PHONE = 'phone',
  INPUT_NUMBER = 'inputNumber',
}
