import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { LocalStorageKeys } from '@/common/constants/localstorage.constants'

import { useLocalStorage } from './useLocalStorage.hooks'

export interface IAuthProvider {
  user: any
  login: any
  logout: any
}

const AuthContext = createContext<IAuthProvider | null>(null)

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [userToken, setUserToken] = useLocalStorage(LocalStorageKeys.ACCESS_TOKEN, null)
  const [, setUserProfile] = useLocalStorage(LocalStorageKeys.USER_PROFILE, null)
  const navigate = useNavigate()

  const login = async (data: any) => {
    setUserToken(data.token)
    setUserProfile(data.profile)
    navigate('/')
  }

  const logout = () => {
    setUserToken(null)
    localStorage.clear()
    navigate('/login', { replace: true })
  }

  const value = useMemo(
    () => ({
      user: userToken,
      login,
      logout,
    }),
    [userToken],
  )
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext) as IAuthProvider
}
