import { createSlice } from '@reduxjs/toolkit'

import { IFilterRealTime } from '@/common/interfaces/filters.interface'

const initialState: IFilterRealTime = {}

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    saveFilter(state, action) {
      return { ...action.payload.dataFilter, ...action.payload }
    },
  },
})

export const { saveFilter } = filterSlice.actions

export default filterSlice.reducer
