import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import { ILevel } from '@/features/levels/interfaces/level.interface'

export interface ILevelState {
  levels: IStoreApiList<ILevel[]>
}

export class LevelState implements ILevelState {
  levels: IStoreApiList<ILevel[]> = new StoreApiList()
}
