import 'antd/dist/antd.min.css'
import 'the-new-css-reset/css/reset.css'
import './common/yup/yup-extensions'
import './styles/global.scss'
import './styles/style.scss'
import './styles/styleFormInputs.scss'

import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { AuthProvider } from './common/hooks/useAuth.hooks'
import { NotificationProvider } from './common/providers/NotificationProvider'

import App from './App'
import { store } from './store/store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <NotificationProvider>
    <BrowserRouter>
      <AuthProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </AuthProvider>
    </BrowserRouter>
  </NotificationProvider>,
)
