import { createSlice } from '@reduxjs/toolkit'

import {
  errorListData,
  errorPostPutItemData,
  receiveListData,
  receivePostPutItemData,
  requestListData,
  requestPostPutItemData,
} from '@/common/utils/state.utils'

import { ISitePrices } from '@/features/sites/interfaces/prices.interfaces'
import { sitePricesService } from '@/features/sites/state/prices/sitePrices.service'
import { IPriceState, PriceState } from '@/features/sites/state/prices/sitePrices.state.interface'

const initialState = new PriceState()

const siteSchedulesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sitePricesService.fetchSitePrices.pending, (state) => {
        return requestListData<IPriceState, ISitePrices>({ ...state }, ['prices'])
      })
      .addCase(sitePricesService.fetchSitePrices.fulfilled, (state, action) => {
        return receiveListData<IPriceState, ISitePrices>({ ...state }, ['prices'], action.payload)
      })
      .addCase(sitePricesService.fetchSitePrices.rejected, (state, action) => {
        return errorListData<IPriceState, ISitePrices>({ ...state }, ['prices'], String(action.error.message))
      })

      .addCase(sitePricesService.fetchSitePrice.pending, (state) => {
        return requestListData<IPriceState, ISitePrices>({ ...state }, ['price'])
      })
      .addCase(sitePricesService.fetchSitePrice.fulfilled, (state, action) => {
        return receiveListData<IPriceState, ISitePrices>({ ...state }, ['price'], action.payload)
      })
      .addCase(sitePricesService.fetchSitePrice.rejected, (state, action) => {
        return errorListData<IPriceState, ISitePrices>({ ...state }, ['price'], String(action.error.message))
      })
      .addCase(sitePricesService.updateSitePrices.pending, (state) => {
        return requestPostPutItemData<IPriceState, ISitePrices>({ ...state }, ['updatePrices'])
      })
      .addCase(sitePricesService.updateSitePrices.fulfilled, (state, action) => {
        return receivePostPutItemData<IPriceState, ISitePrices>({ ...state }, ['updatePrices'], action.payload)
      })
      .addCase(sitePricesService.updateSitePrices.rejected, (state, action) => {
        return errorPostPutItemData<IPriceState, ISitePrices>(
          { ...state },
          ['updatePrices'],
          String(action.error.message),
        )
      })
  },
})

export const {} = siteSchedulesSlice.actions

export default siteSchedulesSlice.reducer
