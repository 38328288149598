import { createSlice } from '@reduxjs/toolkit'

import {
  errorDeleteItem,
  errorItemData,
  errorListData,
  errorPostPutItemData,
  receiveDeleteItem,
  receiveItemData,
  receiveListData,
  receivePostPutItemData,
  requestDeleteItem,
  requestItemData,
  requestListData,
  requestPostPutItemData,
} from '@/common/utils/state.utils'

import {
  IBypassStatus,
  IDoctorAccess,
  IDoctorBasic,
  IDoctorBillingHistory,
  IDoctorDetails,
  IDoctorDocument,
  IDoctorDocumentUpdate,
  IDoctorDocumentsAudit,
  IDoctors,
  IEmployee,
  IEmployeeDetails,
  IEmployees,
  IPatients,
  IPaymentHistory,
  IPermissions,
  IPermissionsUpdate,
  IStaff,
  IStaffDetails,
  IStaffs,
} from 'src/features/users/interfaces/users.interface'

import { usersService } from './users.service'
import { UsersState } from './users.state.interface'

const initialState = new UsersState()

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetSliceError(state, action) {
      return { ...state, [action.payload]: { ...state[action.payload as keyof typeof initialState], errors: null } }
    },
  },
  extraReducers: (builder) => {
    builder

      /// doctors
      .addCase(usersService.fetchDoctors.pending, (state) => {
        return requestListData<UsersState, IDoctors>({ ...state }, ['doctors'])
      })
      .addCase(usersService.fetchDoctors.fulfilled, (state, action) => {
        const { total_items, ...rest } = action.payload
        return receiveListData<UsersState, IDoctors>({ ...state }, ['doctors'], rest, total_items)
      })
      .addCase(usersService.fetchDoctors.rejected, (state, action) => {
        return errorListData<UsersState, IDoctors>({ ...state }, ['doctors'], String(action.error.message))
      })

      /// all doctors basic info
      .addCase(usersService.fetchAllDoctors.pending, (state) => {
        return requestListData<UsersState, IDoctorBasic>({ ...state }, ['doctorsAll'])
      })
      .addCase(usersService.fetchAllDoctors.fulfilled, (state, action) => {
        return receiveListData<UsersState, IDoctorBasic>({ ...state }, ['doctorsAll'], action.payload.doctors)
      })
      .addCase(usersService.fetchAllDoctors.rejected, (state, action) => {
        return errorListData<UsersState, IDoctorBasic>({ ...state }, ['doctorsAll'], String(action.error.message))
      })

      /// doctor

      .addCase(usersService.fetchDoctor.pending, (state) => {
        return requestListData<UsersState, IDoctorDetails>({ ...state }, ['doctor'])
      })
      .addCase(usersService.fetchDoctor.fulfilled, (state, action) => {
        return receiveListData<UsersState, IDoctorDetails>({ ...state }, ['doctor'], action.payload)
      })
      .addCase(usersService.fetchDoctor.rejected, (state, action) => {
        return errorListData<UsersState, IDoctorDetails>({ ...state }, ['doctor'], String(action.error.message))
      })

      /// update doctor

      .addCase(usersService.updateDoctor.pending, (state) => {
        return requestPostPutItemData<UsersState, IDoctorDetails>({ ...state }, ['doctorUpdate'])
      })
      .addCase(usersService.updateDoctor.fulfilled, (state, action) => {
        return receivePostPutItemData<UsersState, IDoctorDetails>({ ...state }, ['doctorUpdate'], action.payload)
      })
      .addCase(usersService.updateDoctor.rejected, (state, action) => {
        return errorPostPutItemData<UsersState, IDoctorDetails>(
          { ...state },
          ['doctorUpdate'],
          String(action.error.message),
        )
      })

      /// doctor

      .addCase(usersService.fetchDoctorBillingHistory.pending, (state) => {
        return requestListData<UsersState, IDoctorBillingHistory[]>({ ...state }, ['doctorBillingHistory'])
      })
      .addCase(usersService.fetchDoctorBillingHistory.fulfilled, (state, action) => {
        const { total_items, items } = action.payload
        return receiveListData<UsersState, IDoctorBillingHistory[]>(
          { ...state },
          ['doctorBillingHistory'],
          items,
          total_items,
        )
      })
      .addCase(usersService.fetchDoctorBillingHistory.rejected, (state, action) => {
        return errorListData<UsersState, IDoctorBillingHistory[]>(
          { ...state },
          ['doctorBillingHistory'],
          String(action.error.message),
        )
      })

      /// payments

      .addCase(usersService.fetchDoctorPayments.pending, (state) => {
        return requestListData<UsersState, IPaymentHistory[]>({ ...state }, ['doctorPayments'])
      })
      .addCase(usersService.fetchDoctorPayments.fulfilled, (state, action) => {
        return receiveListData<UsersState, IPaymentHistory[]>({ ...state }, ['doctorPayments'], action.payload)
      })
      .addCase(usersService.fetchDoctorPayments.rejected, (state, action) => {
        return errorListData<UsersState, IPaymentHistory[]>(
          { ...state },
          ['doctorPayments'],
          String(action.error.message),
        )
      })

      /// delete doctor

      .addCase(usersService.deleteDoctor.pending, (state) => {
        return requestDeleteItem<UsersState, IDoctorDetails>({ ...state }, ['doctor'])
      })
      .addCase(usersService.deleteDoctor.fulfilled, (state, action) => {
        return receiveDeleteItem<UsersState, IDoctorDetails>({ ...state }, ['doctor'])
      })
      .addCase(usersService.deleteDoctor.rejected, (state, action) => {
        return errorDeleteItem<UsersState, IDoctorDetails>({ ...state }, ['doctor'], String(action.error.message))
      })

      // create doctor

      .addCase(usersService.createDoctor.pending, (state) => {
        return requestPostPutItemData<UsersState, IDoctorDetails>({ ...state }, ['doctorCreate'])
      })
      .addCase(usersService.createDoctor.fulfilled, (state, action) => {
        return receivePostPutItemData<UsersState, IDoctorDetails>({ ...state }, ['doctorCreate'], action.payload)
      })
      .addCase(usersService.createDoctor.rejected, (state, action) => {
        return errorPostPutItemData<UsersState, IDoctorDetails>(
          { ...state },
          ['doctorCreate'],
          JSON.stringify(action.payload),
        )
      })
      /// staff
      .addCase(usersService.fetchStaff.pending, (state) => {
        return requestListData<UsersState, IStaffs>({ ...state }, ['staff'])
      })
      .addCase(usersService.fetchStaff.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<UsersState, IStaffs>({ ...state }, ['staff'], items, total_items)
      })
      .addCase(usersService.fetchStaff.rejected, (state, action) => {
        return errorListData<UsersState, IStaffs>({ ...state }, ['staff'], String(action.error.message))
      })

      /// doctor's staff
      .addCase(usersService.fetchDoctorsStaff.pending, (state) => {
        return requestListData<UsersState, IStaffs>({ ...state }, ['staff'])
      })
      .addCase(usersService.fetchDoctorsStaff.fulfilled, (state, action) => {
        const { items, total_items } = action.payload

        return receiveListData<UsersState, IStaffs>({ ...state }, ['staff'], items, total_items)
      })
      .addCase(usersService.fetchDoctorsStaff.rejected, (state, action) => {
        return errorListData<UsersState, IStaffs>({ ...state }, ['staff'], String(action.error.message))
      })

      /// patients
      .addCase(usersService.fetchDoctorsPatients.pending, (state) => {
        return requestListData<UsersState, IPatients>({ ...state }, ['doctorsPatients'])
      })
      .addCase(usersService.fetchDoctorsPatients.fulfilled, (state, action) => {
        const { total_items, ...rest } = action.payload
        return receiveListData<UsersState, IPatients>({ ...state }, ['doctorsPatients'], rest, total_items)
      })
      .addCase(usersService.fetchDoctorsPatients.rejected, (state, action) => {
        return errorListData<UsersState, IPatients>({ ...state }, ['doctorsPatients'], String(action.error.message))
      })

      /// doctor's documents
      .addCase(usersService.fetchDoctorDocuments.pending, (state) => {
        return requestListData<UsersState, IDoctorDocument[]>({ ...state }, ['doctorDocuments'])
      })
      .addCase(usersService.fetchDoctorDocuments.fulfilled, (state, action) => {
        const { documents, ...others } = action.payload
        return receiveListData<UsersState, IDoctorDocument[]>({ ...state }, ['doctorDocuments'], documents, others)
      })
      .addCase(usersService.fetchDoctorDocuments.rejected, (state, action) => {
        return errorListData<UsersState, IDoctorDocument[]>(
          { ...state },
          ['doctorDocuments'],
          String(action.error.message),
        )
      })

      /// doctor's access
      .addCase(usersService.fetchDoctorAccess.pending, (state) => {
        return requestListData<UsersState, IDoctorAccess[]>({ ...state }, ['doctorAccess'])
      })
      .addCase(usersService.fetchDoctorAccess.fulfilled, (state, action) => {
        const { items } = action.payload
        return receiveListData<UsersState, IDoctorAccess[]>({ ...state }, ['doctorAccess'], items)
      })
      .addCase(usersService.fetchDoctorAccess.rejected, (state, action) => {
        return errorListData<UsersState, IDoctorAccess[]>({ ...state }, ['doctorAccess'], String(action.error.message))
      })

      /// doctor's bypass
      .addCase(usersService.fetchDoctorBypass.pending, (state) => {
        return requestItemData<UsersState, IBypassStatus>({ ...state }, ['doctorBypass'])
      })
      .addCase(usersService.fetchDoctorBypass.fulfilled, (state, action) => {
        return receiveListData<UsersState, IBypassStatus>({ ...state }, ['doctorBypass'], action.payload)
      })
      .addCase(usersService.fetchDoctorBypass.rejected, (state, action) => {
        return errorItemData<UsersState, IBypassStatus>({ ...state }, ['doctorBypass'], String(action.error.message))
      })

      /// doctor's documents
      .addCase(usersService.fetchDoctorDocumentsAudit.pending, (state) => {
        return requestListData<UsersState, IDoctorDocumentsAudit[]>({ ...state }, ['doctorDocumentsAudit'])
      })
      .addCase(usersService.fetchDoctorDocumentsAudit.fulfilled, (state, action) => {
        return receiveListData<UsersState, IDoctorDocumentsAudit[]>(
          { ...state },
          ['doctorDocumentsAudit'],
          action.payload,
        )
      })
      .addCase(usersService.fetchDoctorDocumentsAudit.rejected, (state, action) => {
        return errorListData<UsersState, IDoctorDocumentsAudit[]>(
          { ...state },
          ['doctorDocumentsAudit'],
          String(action.error.message),
        )
      })

      /// doctor's document update
      .addCase(usersService.updateDoctorDocument.pending, (state) => {
        return requestPostPutItemData<UsersState, IDoctorDocumentUpdate>({ ...state }, ['doctorDocumentUpdate'])
      })
      .addCase(usersService.updateDoctorDocument.fulfilled, (state, action) => {
        return receiveListData<UsersState, IDoctorDocumentUpdate>(
          { ...state },
          ['doctorDocumentUpdate'],
          action.payload,
        )
      })
      .addCase(usersService.updateDoctorDocument.rejected, (state, action) => {
        return errorPostPutItemData<UsersState, IDoctorDocumentUpdate>(
          { ...state },
          ['doctorDocumentUpdate'],
          String(action.error.message),
        )
      })

      /// update staff
      .addCase(usersService.updateStaff.pending, (state) => {
        return requestPostPutItemData<UsersState, IStaff>({ ...state }, ['staffUpdate'])
      })
      .addCase(usersService.updateStaff.fulfilled, (state, action) => {
        return receivePostPutItemData<UsersState, IStaff>({ ...state }, ['staffUpdate'], action.payload)
      })
      .addCase(usersService.updateStaff.rejected, (state, action) => {
        return errorPostPutItemData<UsersState, IStaff>({ ...state }, ['staffUpdate'], String(action.error.message))
      })

      /// staff details
      .addCase(usersService.fetchStaffDetails.pending, (state) => {
        return requestItemData<UsersState, IStaffDetails>({ ...state }, ['staffDetails'])
      })
      .addCase(usersService.fetchStaffDetails.fulfilled, (state, action) => {
        return receiveItemData<UsersState, IStaffDetails>({ ...state }, ['staffDetails'], action.payload)
      })
      .addCase(usersService.fetchStaffDetails.rejected, (state, action) => {
        return errorItemData<UsersState, IStaffDetails>({ ...state }, ['staffDetails'], String(action.error.message))
      })

      /// employees
      .addCase(usersService.fetchEmployees.pending, (state) => {
        return requestListData<UsersState, IEmployees>({ ...state }, ['employees'])
      })
      .addCase(usersService.fetchEmployees.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<UsersState, IEmployees>({ ...state }, ['employees'], items, total_items)
      })
      .addCase(usersService.fetchEmployees.rejected, (state, action) => {
        return errorListData<UsersState, IEmployees>({ ...state }, ['employees'], String(action.error.message))
      })

      /// employee
      .addCase(usersService.fetchEmployee.pending, (state) => {
        return requestItemData<UsersState, IEmployeeDetails>({ ...state }, ['employee'])
      })
      .addCase(usersService.fetchEmployee.fulfilled, (state, action) => {
        return receiveItemData<UsersState, IEmployeeDetails>({ ...state }, ['employee'], action.payload)
      })
      .addCase(usersService.fetchEmployee.rejected, (state, action) => {
        return errorItemData<UsersState, IEmployeeDetails>({ ...state }, ['employee'], String(action.error.message))
      })

      /// update employee

      .addCase(usersService.updateEmployee.pending, (state) => {
        return requestPostPutItemData<UsersState, IEmployee>({ ...state }, ['employeeUpdate'])
      })
      .addCase(usersService.updateEmployee.fulfilled, (state, action) => {
        return receivePostPutItemData<UsersState, IEmployee>({ ...state }, ['employeeUpdate'], action.payload)
      })
      .addCase(usersService.updateEmployee.rejected, (state, action) => {
        return errorPostPutItemData<UsersState, IEmployee>(
          { ...state },
          ['employeeUpdate'],
          String(action.error.message),
        )
      })

      // create employee

      .addCase(usersService.createEmployee.pending, (state) => {
        return requestPostPutItemData<UsersState, IEmployee>({ ...state }, ['employeeCreate'])
      })
      .addCase(usersService.createEmployee.fulfilled, (state, action) => {
        return receivePostPutItemData<UsersState, IEmployee>({ ...state }, ['employeeCreate'], action.payload)
      })
      .addCase(usersService.createEmployee.rejected, (state, action) => {
        return errorPostPutItemData<UsersState, IEmployee>(
          { ...state },
          ['employeeCreate'],
          JSON.stringify(action.payload),
        )
      })

      /// permissions
      .addCase(usersService.fetchPermissions.pending, (state) => {
        return requestListData<UsersState, IPermissions>({ ...state }, ['permissions'])
      })
      .addCase(usersService.fetchPermissions.fulfilled, (state, action) => {
        return receiveListData<UsersState, IPermissions>({ ...state }, ['permissions'], action.payload)
      })
      .addCase(usersService.fetchPermissions.rejected, (state, action) => {
        return errorListData<UsersState, IPermissions>({ ...state }, ['permissions'], String(action.error.message))
      })

      .addCase(usersService.updatePermissions.pending, (state) => {
        return requestPostPutItemData<UsersState, IPermissionsUpdate[]>({ ...state }, ['permissionUpdate'])
      })
      .addCase(usersService.updatePermissions.fulfilled, (state, action) => {
        return receivePostPutItemData<UsersState, IPermissionsUpdate[]>(
          { ...state },
          ['permissionUpdate'],
          action.payload,
        )
      })
      .addCase(usersService.updatePermissions.rejected, (state, action) => {
        return errorPostPutItemData<UsersState, IPermissionsUpdate[]>(
          { ...state },
          ['permissionUpdate'],
          String(action.error.message),
        )
      })
  },
})

export const { resetSliceError } = usersSlice.actions

export default usersSlice.reducer
