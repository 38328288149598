import { createAsyncThunk } from '@reduxjs/toolkit'
import type { TablePaginationConfig } from 'antd/es/table'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'
import { buildPaginationQuery } from '@/common/utils/table.utils'

import { IHardware } from '@/features/hardware/interfaces/hardware.interface'

const baseUrl = `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/hardwaretypes`

const fetchHardwares = createAsyncThunk('hardwares/fetchHardwares', async (tableParams: TablePaginationConfig) => {
  const response = await api(HttpMethods.GET, buildPaginationQuery(baseUrl, tableParams), {})
  return response.data
})

const fetchHardware = createAsyncThunk('hardwares/fetchHardware', async (id: number) => {
  const response = await api(HttpMethods.GET, `${baseUrl}/${id}`, {})
  return response.data.item
})

const deleteHardware = createAsyncThunk('hardwares/deleteHardware', async (id: number) => {
  const response = await api(HttpMethods.DELETE, `${baseUrl}/${id}`, {})
  return response.data
})

const updateHardware = createAsyncThunk('hardwares/updateHardware', async (data: Partial<IHardware>) => {
  const response = await api(HttpMethods.PUT, `${baseUrl}/${data.id}`, data)
  return response.data
})

const addHardware = createAsyncThunk('rooms/addHardware', async (data: {}) => {
  const response = await api(HttpMethods.POST, baseUrl, data)
  return response.data
})

export const hardwaresService = {
  fetchHardwares,
  fetchHardware,
  deleteHardware,
  updateHardware,
  addHardware,
}
