import { createSlice } from '@reduxjs/toolkit'

import { IAppState } from '@/common/interfaces/app-state.interface'

const initialState: IAppState = {
  isSidebarVisible: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSidebar(state, action) {
      return { ...state, isSidebarVisible: action.payload }
    },
  },
})

export const { toggleSidebar } = appSlice.actions

export default appSlice.reducer
