import { createAsyncThunk } from '@reduxjs/toolkit'
import { TablePaginationConfig } from 'antd'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'
import { buildPaginationQuery } from '@/common/utils/table.utils'

import { IFaq } from 'src/features/faq/interfaces/faq.interface'

const baseUrl = `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/faqs`

const fetchFaqs = createAsyncThunk('faqs/fetchFaqs', async (tableParams: TablePaginationConfig) => {
  const response = await api(HttpMethods.GET, buildPaginationQuery(baseUrl, tableParams))

  return response.data
})

const fetchFaq = createAsyncThunk('faqs/fetchFaq', async (id: number) => {
  const response = await api(HttpMethods.GET, `${baseUrl}/${id}`)

  return response.data
})

const updateFaq = createAsyncThunk('faqs/updateFaq', async ({ id, data }: { id: number; data: IFaq }) => {
  const response = await api(HttpMethods.PUT, `${baseUrl}/${id}`, data)
  return response.data
})

const deleteFaq = createAsyncThunk('faqs/deleteFaq', async (id: number) => {
  const response = await api(HttpMethods.DELETE, `${baseUrl}/${id}`)
  return response.data
})

const createFaq = createAsyncThunk('faqs/createFaq', async (data: IFaq) => {
  const response = await api(HttpMethods.POST, baseUrl, data)
  return response.data
})

export const faqsService = {
  fetchFaqs,
  updateFaq,
  deleteFaq,
  createFaq,
  fetchFaq,
}
