import { FC } from 'react'
import './page-spinner.scss'

import { Spin } from 'antd'

const PageSpinner: FC = () => (
  <div className='whole-page-wrapper'>
    <Spin size='large' />
  </div>
)

export default PageSpinner
