import { createAsyncThunk } from '@reduxjs/toolkit'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'

import { IProfileUpdate } from 'src/features/settings/interfaces/settings.interface'

const baseUrl = `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/profile`

const fetchProfile = createAsyncThunk('settings/fetchProfile', async () => {
  const response = await api(HttpMethods.GET, baseUrl)
  return response.data
})

const updateProfile = createAsyncThunk('settings/updateProfile', async (data: IProfileUpdate) => {
  const response = await api(HttpMethods.PUT, baseUrl, data)
  return response.data
})

const fetchProfileImage = createAsyncThunk('settings/fetchProfileImage', async () => {
  const response = await api(HttpMethods.GET, `${baseUrl}-image`)
  return response.data
})

const addProfileImage = createAsyncThunk(
  'settings/addProfileImage',
  async ({ name, path }: { name: string; path: string }) => {
    const response = await api(HttpMethods.POST, `${baseUrl}-image`, { name, path })
    return response.data
  },
)

const deleteProfileImage = createAsyncThunk('settings/deleteProfileImage', async (id: string | number) => {
  const response = await api(HttpMethods.DELETE, `${baseUrl}-image/${id}`)
  return response.data
})

export const settingsService = {
  fetchProfile,
  updateProfile,
  fetchProfileImage,
  addProfileImage,
  deleteProfileImage,
}
