import { createAsyncThunk } from '@reduxjs/toolkit'
import type { TablePaginationConfig } from 'antd/es/table'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'
import { buildPaginationQuery } from '@/common/utils/table.utils'

import { IUploadedFileSrc } from '@/features/support/interfaces/support.interface'

const fetchTickets = createAsyncThunk(
  'support/fetchTickets',
  async (payload: { key: string; tableParams: TablePaginationConfig }) => {
    const response = await api(
      HttpMethods.GET,
      `${buildPaginationQuery(
        `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/support-tickets`,
        payload.tableParams,
      )}&filter=${payload.key}`,
      {},
    )
    return response.data
  },
)

const fetchTicketDetails = createAsyncThunk('support/fetchTicketDetails', async (id: string) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/support-tickets/${id}`, {})
  return response.data
})

const addTicketMessage = createAsyncThunk(
  'support/addTicketMessage',
  async ({ id, data }: { id: string; data: { message: string; attachments: IUploadedFileSrc[] } }) => {
    const response = await api(
      HttpMethods.POST,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/support-tickets/${id}/add-message`,
      data,
    )
    return response.data
  },
)

const updateTicketStatus = createAsyncThunk(
  'support/updateTicketStatus',
  async ({ id, data }: { id: string; data: { status: number } }) => {
    const response = await api(
      HttpMethods.PUT,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/support-tickets/${id}`,
      data,
    )
    return response.data
  },
)
export const supportService = {
  fetchTickets,
  fetchTicketDetails,
  addTicketMessage,
  updateTicketStatus,
}
