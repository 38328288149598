import { createAsyncThunk } from '@reduxjs/toolkit'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'

const fetchPermissions = createAsyncThunk('permissions/fetchPermissions', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/allowed-permissions`, {})
  return response.data
})

export const permissionsService = {
  fetchPermissions,
}
