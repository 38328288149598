import { createSlice } from '@reduxjs/toolkit'

import { ICountry, IType } from '@/common/interfaces/type.interfaces'
import { errorListData, receiveListData, requestListData } from '@/common/utils/state.utils'

import { typesService } from './types.service'
import { ITypesState, TypesState } from './types.state.interface'

const initialState = new TypesState()

const typesSlice = createSlice({
  name: 'types',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(typesService.fetchTypes.pending, (state) => {
        return requestListData<ITypesState, IType[]>({ ...state }, ['types'])
      })
      .addCase(typesService.fetchTypes.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<ITypesState, IType[]>(
          { ...state, [`type${action.meta.arg.classCode}`]: action.payload.items },
          ['types'],
          items,
          total_items,
        )
      })
      .addCase(typesService.fetchTypes.rejected, (state, action) => {
        return errorListData<ITypesState, IType[]>({ ...state }, ['types'], String(action.error.message))
      })

      // countries
      .addCase(typesService.fetchCountries.pending, (state) => {
        return requestListData<ITypesState, ICountry[]>({ ...state }, ['countries'])
      })
      .addCase(typesService.fetchCountries.fulfilled, (state, action) => {
        return receiveListData<ITypesState, ICountry[]>({ ...state }, ['countries'], action.payload)
      })
      .addCase(typesService.fetchCountries.rejected, (state, action) => {
        return errorListData<ITypesState, ICountry[]>({ ...state }, ['countries'], String(action.error.message))
      })
  },
})

export default typesSlice.reducer
