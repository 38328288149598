import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import FallbackComponent from '@/common/components/fallback-component/fallback-component'

const PrivateRoute = lazy(() => import('@/common/components/private-route/private-route'))
const LoginPage = lazy(() => import('@/features/auth/pages/login/login-page'))
const ForgotPassword = lazy(() => import('@/features/auth/pages/forgot-password/forgot-password'))
const ResetPassword = lazy(() => import('@/features/auth/pages/reset-password/reset-password'))

const App = () => {
  return (
    <Routes>
      <Route
        path='*'
        element={
          <FallbackComponent>
            <PrivateRoute />
          </FallbackComponent>
        }
      />
      <Route
        path='/login'
        element={
          <FallbackComponent>
            <LoginPage />
          </FallbackComponent>
        }
      />
      <Route
        path='/forgot-password'
        element={
          <FallbackComponent>
            <ForgotPassword />
          </FallbackComponent>
        }
      />

      <Route
        path='/auth/set-new-password'
        element={
          <FallbackComponent>
            <ResetPassword />
          </FallbackComponent>
        }
      />
    </Routes>
  )
}

export default App
