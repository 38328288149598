import { createSlice } from '@reduxjs/toolkit'

import { IUserPermissions } from '@/common/interfaces/permissions.interface'
import { errorListData, receiveListData, requestListData } from '@/common/utils/state.utils'

import { permissionsService } from './permissions.service'
import { IPermissionsState, PermissionsState } from './permissions.state.interface'

const initialState = new PermissionsState()

const imagesSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(permissionsService.fetchPermissions.pending, (state) => {
        return requestListData<IPermissionsState, IUserPermissions>({ ...state }, ['permissions'])
      })
      .addCase(permissionsService.fetchPermissions.fulfilled, (state, action) => {
        return receiveListData<IPermissionsState, IUserPermissions>({ ...state }, ['permissions'], action.payload)
      })
      .addCase(permissionsService.fetchPermissions.rejected, (state, action) => {
        return errorListData<IPermissionsState, IUserPermissions>(
          { ...state },
          ['permissions'],
          String(action.error.message),
        )
      })
  },
})

export default imagesSlice.reducer
