import { createSlice } from '@reduxjs/toolkit'

import { errorListData, receiveListData, requestListData } from '@/common/utils/state.utils'

import { IHardware, IHardwareFilterData } from '@/features/sites/interfaces/hardware.interface'
import { siteHardwaresService } from '@/features/sites/state/hardwares/siteHardwares.service'
import { IHardwareState, HardwareState } from '@/features/sites/state/hardwares/siteHardwares.state.interface'

const initialState = new HardwareState()

const siteHardwaresSlice = createSlice({
  name: 'hardwares',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(siteHardwaresService.fetchHardwares.pending, (state) => {
        return requestListData<IHardwareState, IHardware[]>({ ...state }, ['siteHardwares'])
      })
      .addCase(siteHardwaresService.fetchHardwares.fulfilled, (state, action) => {
        const {
          items,
          total_items,
          rooms,
          hardware_type,
          state: states,
          device_status_type_code,
          device_state_type_code,
        } = action.payload
        return receiveListData<IHardwareState, IHardwareFilterData>(
          { ...state },
          ['siteHardwares'],
          { items, rooms, hardware_type, states, device_status_type_code, device_state_type_code },
          total_items,
        )
      })
      .addCase(siteHardwaresService.fetchHardwares.rejected, (state, action) => {
        return errorListData<IHardwareState, IHardware[]>({ ...state }, ['siteHardwares'], String(action.error.message))
      })

      .addCase(siteHardwaresService.fetchHardwareDetails.pending, (state) => {
        return requestListData<HardwareState, IHardware>({ ...state }, ['hardwareDetails'])
      })
      .addCase(siteHardwaresService.fetchHardwareDetails.fulfilled, (state, action) => {
        return receiveListData<HardwareState, IHardware>({ ...state }, ['hardwareDetails'], action.payload)
      })
      .addCase(siteHardwaresService.fetchHardwareDetails.rejected, (state, action) => {
        return errorListData<HardwareState, IHardware>({ ...state }, ['hardwareDetails'], String(action.error.message))
      })

      .addCase(siteHardwaresService.fetchSiteHardwareTemplates.pending, (state) => {
        return requestListData<HardwareState, IHardware>({ ...state }, ['hardwareTemplates'])
      })
      .addCase(siteHardwaresService.fetchSiteHardwareTemplates.fulfilled, (state, action) => {
        return receiveListData<HardwareState, IHardware>({ ...state }, ['hardwareTemplates'], action.payload)
      })
      .addCase(siteHardwaresService.fetchSiteHardwareTemplates.rejected, (state, action) => {
        return errorListData<HardwareState, IHardware>(
          { ...state },
          ['hardwareTemplates'],
          String(action.error.message),
        )
      })

      .addCase(siteHardwaresService.fetchDynamicFields.pending, (state) => {
        return requestListData<HardwareState, IHardware>({ ...state }, ['dynamicFields'])
      })
      .addCase(siteHardwaresService.fetchDynamicFields.fulfilled, (state, action) => {
        return receiveListData<HardwareState, IHardware>({ ...state }, ['dynamicFields'], action.payload)
      })
      .addCase(siteHardwaresService.fetchDynamicFields.rejected, (state, action) => {
        return errorListData<HardwareState, IHardware>({ ...state }, ['dynamicFields'], String(action.error.message))
      })
  },
})

export default siteHardwaresSlice.reducer
