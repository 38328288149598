import { createAsyncThunk } from '@reduxjs/toolkit'
import { TablePaginationConfig } from 'antd'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'
import { buildPaginationQuery } from '@/common/utils/table.utils'

import { IDictionary } from 'src/features/terms/interfaces/terms.interface'

const baseUrl = `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/dictionary`

const fetchDictionaries = createAsyncThunk(
  'dictionary/fetchDictionaries',
  async (tableParams: TablePaginationConfig) => {
    const response = await api(HttpMethods.GET, buildPaginationQuery(baseUrl, tableParams))
    return response.data
  },
)

const fetchDictionary = createAsyncThunk('dictionary/fetchDictionary', async (id: number) => {
  const response = await api(HttpMethods.GET, `${baseUrl}/${id}`)
  return response.data
})

const updateDictionary = createAsyncThunk(
  'dictionary/updateDictionary',
  async ({ id, data }: { id: number; data: IDictionary }) => {
    const response = await api(HttpMethods.PUT, `${baseUrl}/${id}`, data)
    return response.data
  },
)

const deleteDictionary = createAsyncThunk('dictionary/deleteDictionary', async (id: number) => {
  const response = await api(HttpMethods.DELETE, `${baseUrl}/${id}`)
  return response.data
})

const createDictionary = createAsyncThunk('dictionary/createDictionary', async (data: IDictionary) => {
  const response = await api(HttpMethods.POST, baseUrl, data)
  return response.data
})

export const dictionaryService = {
  fetchDictionaries,
  updateDictionary,
  deleteDictionary,
  createDictionary,
  fetchDictionary,
}
