import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import { ICondition } from 'src/features/terms/interfaces/terms.interface'

export interface IConditionState {
  condition: IStoreApiItem<ICondition>
  conditionUpdate: IStoreApiItem<ICondition>
}

export class ConditionState implements IConditionState {
  condition: IStoreApiList<ICondition> = new StoreApiList()
  conditionUpdate: IStoreApiItem<ICondition> = new StoreApiItem()
}
