import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'

import {
  IProfile,
  IProfileImage,
  IProfileUpdate,
  IAddProfileImage,
} from 'src/features/settings/interfaces/settings.interface'

export interface ISettingsState {
  profile: IStoreApiItem<IProfile>
  profileUpdate: IStoreApiItem<IProfileUpdate>
  profileImageAdd: IStoreApiItem<IAddProfileImage>
  profileImage: IStoreApiItem<IProfileImage>
  profileImageDelete: IStoreApiItem<null>
}

export class SettingsState implements ISettingsState {
  profile: IStoreApiItem<IProfile> = new StoreApiItem()
  profileUpdate: IStoreApiItem<IProfileUpdate> = new StoreApiItem()
  profileImageAdd: IStoreApiItem<IAddProfileImage> = new StoreApiItem()
  profileImage: IStoreApiItem<IProfileImage> = new StoreApiItem()
  profileImageDelete: IStoreApiItem<null> = new StoreApiItem()
}
