import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import {
  errorDeleteItem,
  errorItemData,
  errorListData,
  errorPostPutItemData,
  receiveDeleteItem,
  receiveItemData,
  receiveListData,
  receivePostPutItemData,
  requestDeleteItem,
  requestItemData,
  requestListData,
  requestPostPutItemData,
} from '@/common/utils/state.utils'

import { IFaqs, IFaq } from 'src/features/faq/interfaces/faq.interface'

import { faqsService } from './faq.service'
import { FaqState } from './faq.state.interface'

const initialState = new FaqState()

const faqSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    updateFaqs: (state, action: PayloadAction<IFaq[]>) => {
      state.faqs.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      /// get faqs
      .addCase(faqsService.fetchFaqs.pending, (state) => {
        return requestListData<FaqState, IFaqs>({ ...state }, ['faqs'])
      })
      .addCase(faqsService.fetchFaqs.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<FaqState, IFaqs>({ ...state }, ['faqs'], items, total_items)
      })
      .addCase(faqsService.fetchFaqs.rejected, (state, action) => {
        return errorListData<FaqState, IFaqs>({ ...state }, ['faqs'], String(action.error.message))
      })

      /// get faq
      .addCase(faqsService.fetchFaq.pending, (state) => {
        return requestItemData<FaqState, IFaq>({ ...state }, ['faq'])
      })
      .addCase(faqsService.fetchFaq.fulfilled, (state, action) => {
        return receiveItemData<FaqState, IFaq>({ ...state }, ['faq'], action.payload.item)
      })
      .addCase(faqsService.fetchFaq.rejected, (state, action) => {
        return errorItemData<FaqState, IFaq>({ ...state }, ['faq'], String(action.error.message))
      })
      /// create faq
      .addCase(faqsService.createFaq.pending, (state) => {
        return requestPostPutItemData<FaqState, IFaq>({ ...state }, ['faqCreate'])
      })
      .addCase(faqsService.createFaq.fulfilled, (state, action) => {
        return receivePostPutItemData<FaqState, IFaq>({ ...state }, ['faqCreate'], action.payload)
      })
      .addCase(faqsService.createFaq.rejected, (state, action) => {
        return errorPostPutItemData<FaqState, IFaq>({ ...state }, ['faqCreate'], String(action.error.message))
      })

      /// update faq
      .addCase(faqsService.updateFaq.pending, (state) => {
        return requestPostPutItemData<FaqState, IFaq>({ ...state }, ['faqUpdate'])
      })
      .addCase(faqsService.updateFaq.fulfilled, (state, action) => {
        return receivePostPutItemData<FaqState, IFaq>({ ...state }, ['faqUpdate'], action.payload)
      })
      .addCase(faqsService.updateFaq.rejected, (state, action) => {
        return errorPostPutItemData<FaqState, IFaq>({ ...state }, ['faqUpdate'], String(action.error.message))
      })

      /// delete faq
      .addCase(faqsService.deleteFaq.pending, (state) => {
        return requestDeleteItem<FaqState, IFaq>({ ...state }, ['faqDelete'])
      })
      .addCase(faqsService.deleteFaq.fulfilled, (state) => {
        return receiveDeleteItem<FaqState, IFaq>({ ...state }, ['faqDelete'])
      })
      .addCase(faqsService.deleteFaq.rejected, (state, action) => {
        return errorDeleteItem<FaqState, IFaq>({ ...state }, ['faqDelete'], String(action.error.message))
      })
  },
})

export const { updateFaqs } = faqSlice.actions

export default faqSlice.reducer
