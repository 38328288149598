import { createSlice } from '@reduxjs/toolkit'

import { errorListData, receiveListData, requestListData } from '@/common/utils/state.utils'

import { IRentsTax, ISalesTax } from 'src/features/sites/interfaces/taxes.interface'

import { taxService } from './taxes.service'
import { TaxesState } from './taxes.state.interface'

const initialState = new TaxesState()

const taxesSlice = createSlice({
  name: 'taxes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      /// sales taxes
      .addCase(taxService.fetchSalesTax.pending, (state) => {
        return requestListData<TaxesState, ISalesTax[]>({ ...state }, ['salesTaxes'])
      })
      .addCase(taxService.fetchSalesTax.fulfilled, (state, action) => {
        const { total_items, items } = action.payload
        return receiveListData<TaxesState, ISalesTax[]>({ ...state }, ['salesTaxes'], items, total_items)
      })
      .addCase(taxService.fetchSalesTax.rejected, (state, action) => {
        return errorListData<TaxesState, ISalesTax[]>({ ...state }, ['salesTaxes'], String(action.error.message))
      })

      // rent taxes
      .addCase(taxService.fetchRentTax.pending, (state) => {
        return requestListData<TaxesState, IRentsTax[]>({ ...state }, ['rentTaxes'])
      })
      .addCase(taxService.fetchRentTax.fulfilled, (state, action) => {
        const { total_items, items } = action.payload
        return receiveListData<TaxesState, IRentsTax[]>({ ...state }, ['rentTaxes'], items, total_items)
      })
      .addCase(taxService.fetchRentTax.rejected, (state, action) => {
        return errorListData<TaxesState, IRentsTax[]>({ ...state }, ['rentTaxes'], String(action.error.message))
      })

      // fee taxes
      .addCase(taxService.fetchFees.pending, (state) => {
        return requestListData<TaxesState, ISalesTax[]>({ ...state }, ['fees'])
      })
      .addCase(taxService.fetchFees.fulfilled, (state, action) => {
        const { total_items, items } = action.payload
        return receiveListData<TaxesState, ISalesTax[]>({ ...state }, ['fees'], items, total_items)
      })
      .addCase(taxService.fetchFees.rejected, (state, action) => {
        return errorListData<TaxesState, ISalesTax[]>({ ...state }, ['fees'], String(action.error.message))
      })

      // deposit taxes
      .addCase(taxService.fetchDeposit.pending, (state) => {
        return requestListData<TaxesState, ISalesTax[]>({ ...state }, ['deposit'])
      })
      .addCase(taxService.fetchDeposit.fulfilled, (state, action) => {
        const { total_items, items } = action.payload
        return receiveListData<TaxesState, ISalesTax[]>({ ...state }, ['deposit'], items, total_items)
      })
      .addCase(taxService.fetchDeposit.rejected, (state, action) => {
        return errorListData<TaxesState, ISalesTax[]>({ ...state }, ['deposit'], String(action.error.message))
      })
  },
})

export default taxesSlice.reducer
