import { StoreApiList, IStoreApiList } from '@/common/interfaces/store-api-list.interface'
import { ICountry, IType } from '@/common/interfaces/type.interfaces'

export interface ITypesState {
  types: IStoreApiList<IType[]>
  countries: IStoreApiList<ICountry[]>
}

export class TypesState implements ITypesState {
  [x: string]: any
  types: IStoreApiList<IType[]> = new StoreApiList()
  countries: IStoreApiList<ICountry[]> = new StoreApiList()
}
