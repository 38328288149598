import { createAsyncThunk } from '@reduxjs/toolkit'
import type { TablePaginationConfig } from 'antd/es/table'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'
import { buildPaginationQuery } from '@/common/utils/table.utils'

import { IDoctorDocumentUpdate, IPatient, IPermissionsUpdate } from 'src/features/users/interfaces/users.interface'

const fetchDoctors = createAsyncThunk(
  'users/fetchDoctors',
  async (params: { tableParams: TablePaginationConfig; filterValue?: string }) => {
    const response = await api(
      HttpMethods.GET,
      `${buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors`, params.tableParams)}${
        !!params.filterValue ? `&search=${params.filterValue}` : ''
      }`,
    )
    return response.data
  },
)

const fetchAllDoctors = createAsyncThunk('users/fetchAllDoctors', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/all-doctors`)
  return response.data
})

const fetchDoctor = createAsyncThunk('users/fetchDoctor', async (id: string | number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${id}`)
  return response.data
})

const fetchDoctorAccess = createAsyncThunk('users/fetchDoctorAccess', async (id: string | number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${id}/access`)
  return response.data
})

const fetchDoctorsPatients = createAsyncThunk(
  'users/fetchDoctorsPatients',
  async (payload: { id: string | number; tableParams: TablePaginationConfig }) => {
    const response = await api(
      HttpMethods.GET,
      buildPaginationQuery(
        `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${payload.id}/patients`,
        payload.tableParams,
      ),
    )
    return response.data
  },
)

const updateDoctorsPatient = createAsyncThunk(
  'users/updateDoctorsPatient',
  async ({ doctorId, data, patientId }: { doctorId: string | number; patientId: number; data: Partial<IPatient> }) => {
    const response = await api(
      HttpMethods.PUT,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${doctorId}/patients/${patientId}`,
      data,
    )
    return response.data
  },
)

const addDoctorsPatient = createAsyncThunk(
  'users/addDoctorsPatient',
  async ({ doctorId, data }: { doctorId: string | number; data: Partial<IPatient> }) => {
    const response = await api(
      HttpMethods.POST,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${doctorId}/patients`,
      data,
    )
    return response.data
  },
)

const resendEmailVerification = createAsyncThunk(
  'users/resendEmailVerification',
  async ({ doctorId, email }: { doctorId: string | number; email: string }) => {
    const response = await api(
      HttpMethods.POST,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${doctorId}/send-mail`,
      { email },
    )
    return response.data
  },
)

const doctorResetPassword = createAsyncThunk('users/doctorResetPassword', async (doctorId: string | number) => {
  const response = await api(
    HttpMethods.POST,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${doctorId}/reset-password`,
  )
  return response.data
})

const staffResetPassword = createAsyncThunk('users/staffResetPassword', async (staffId: string | number) => {
  const response = await api(
    HttpMethods.POST,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/staff/${staffId}/reset-password`,
  )
  return response.data
})

const employeeResetPassword = createAsyncThunk('users/employeeResetPassword', async (employeeId: string | number) => {
  const response = await api(
    HttpMethods.POST,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/employees/${employeeId}/reset-password`,
  )
  return response.data
})

const deleteDoctorsPatient = createAsyncThunk(
  'users/deleteDoctorsPatient',
  async ({ doctorId, patientId }: { doctorId: string | number; patientId: number }) => {
    const response = await api(
      HttpMethods.DELETE,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${doctorId}/patients/${patientId}`,
    )
    return response.data
  },
)

const fetchDoctorBypass = createAsyncThunk('users/fetchDoctorBypass', async (id: string | number) => {
  const response = await api(
    HttpMethods.GET,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${id}/bypass-payment`,
  )
  return response.data
})

const updateDoctorBypass = createAsyncThunk(
  'users/updateDoctorBypass',
  async ({
    id,
    bypass_payment,
    is_test_account,
  }: {
    id: string | number
    bypass_payment: 0 | 1
    is_test_account: 0 | 1
  }) => {
    const response = await api(
      HttpMethods.POST,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${id}/bypass-payment`,
      { bypass_payment, is_test_account },
    )
    return response.data
  },
)

const updateDoctorAccess = createAsyncThunk(
  'users/updateDoctorAccess',
  async (params: { id: string | number; is_checked: number; site_id: number }) => {
    const { id, ...rest } = params
    const response = await api(
      HttpMethods.POST,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${id}/access`,
      rest,
    )
    return response.data
  },
)

const updateDoctor = createAsyncThunk(
  'users/updateDoctor',
  async ({ id, data }: { id: string | number; data: any }) => {
    const response = await api(HttpMethods.PUT, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${id}`, data)
    return response.data
  },
)

const deleteDoctor = createAsyncThunk('users/deleteDoctor', async (id: string | number) => {
  const response = await api(HttpMethods.DELETE, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${id}`)
  return response.data
})

const createDoctor = createAsyncThunk('users/createDoctor', async (data: any, { rejectWithValue }) => {
  try {
    const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors`, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.error)
  }
})

const fetchDoctorDocuments = createAsyncThunk('users/fetchDoctorDocuments', async (id: string | number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${id}/documents`)
  return response.data
})

const fetchDoctorBillingHistory = createAsyncThunk(
  'users/fetchDoctorBillingHistory',
  async (payload: { id: string | number; tableParams: TablePaginationConfig }) => {
    const response = await api(
      HttpMethods.GET,
      buildPaginationQuery(
        `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${payload.id}/billing-history`,
        payload.tableParams,
      ),
    )
    return response.data
  },
)

const fetchDoctorPayments = createAsyncThunk('users/fetchDoctorPayments', async (id: string | number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${id}/payments`)
  return response.data.transactions
})

const fetchDoctorDocumentsAudit = createAsyncThunk('users/fetchDoctorDocumentsAudit', async (id: string | number) => {
  const response = await api(
    HttpMethods.GET,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${id}/documents/audit`,
  )
  return response.data
})

const updateDoctorDocument = createAsyncThunk(
  'users/updateDoctorDocument',
  async (documents: Array<IDoctorDocumentUpdate>) => {
    const response = await api(HttpMethods.PUT, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctor-documents`, {
      documents,
    })
    return response.data
  },
)

const fetchStaff = createAsyncThunk(
  'users/fetchStaff',
  async (params: { tableParams: TablePaginationConfig; filterValue?: string }) => {
    const response = await api(
      HttpMethods.GET,
      `${buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/staff`, params.tableParams)}${
        !!params.filterValue ? `&search=${params.filterValue}` : ''
      }`,
    )
    return response.data
  },
)

const fetchDoctorsStaff = createAsyncThunk(
  'users/fetchDoctorsStaff',
  async (payload: { id: string | number; tableParams: TablePaginationConfig; filterValue?: string }) => {
    const response = await api(
      HttpMethods.GET,
      `${buildPaginationQuery(
        `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${payload.id}/staff`,
        payload.tableParams,
      )}${!!payload.filterValue ? `&search=${payload.filterValue}` : ''}`,
    )
    return response.data
  },
)

const updateStaff = createAsyncThunk('users/updateStaff', async ({ id, data }: { id: string | number; data: any }) => {
  const response = await api(HttpMethods.PUT, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/staff/${id}`, data)
  return response.data
})

const createStaff = createAsyncThunk('users/createStaff', async (data: any, { rejectWithValue }) => {
  try {
    const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/staff`, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.error)
  }
})

const fetchStaffDetails = createAsyncThunk('users/fetchStaffDetails', async (id: string | number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/staff/${id}`)
  return response.data
})

const fetchEmployees = createAsyncThunk(
  'users/fetchEmployees',
  async (payload: { searchParams?: string; tableParams: TablePaginationConfig; filterValue?: string }) => {
    const response = await api(
      HttpMethods.GET,
      `${buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/employees`, payload.tableParams)}${
        payload.searchParams ? '&' + payload.searchParams : ''
      }${!!payload.filterValue ? `&search=${payload.filterValue}` : ''}`,
    )
    return response.data
  },
)

const updateEmployee = createAsyncThunk(
  'users/updateEmployee',
  async ({ id, data }: { id: string | number; data: any }) => {
    const response = await api(HttpMethods.PUT, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/employees/${id}`, data)
    return response.data
  },
)

const fetchEmployee = createAsyncThunk('users/fetchEmployee', async (id: string | number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/employees/${id}`)
  return response.data
})

const createEmployee = createAsyncThunk('users/createEmployee', async (data: any, { rejectWithValue }) => {
  try {
    const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/employees`, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.error)
  }
})

const fetchPermissions = createAsyncThunk('users/fetchPermissions', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/permissions`)
  return response.data
})

const updatePermissions = createAsyncThunk('users/updatePermission', async (data: IPermissionsUpdate[]) => {
  const response = await api(
    HttpMethods.PUT,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/permissions/manage-roles`,
    {
      data,
    },
  )
  return response.data
})

export const usersService = {
  fetchDoctors,
  fetchDoctor,
  deleteDoctor,
  updateDoctor,
  createDoctor,
  fetchDoctorDocuments,
  fetchDoctorDocumentsAudit,
  fetchStaff,
  updateStaff,
  createStaff,
  fetchStaffDetails,
  fetchDoctorsStaff,
  fetchAllDoctors,
  fetchEmployees,
  updateEmployee,
  fetchEmployee,
  createEmployee,
  updateDoctorDocument,
  fetchPermissions,
  updatePermissions,
  fetchDoctorPayments,
  fetchDoctorBillingHistory,
  fetchDoctorAccess,
  updateDoctorAccess,
  fetchDoctorsPatients,
  updateDoctorsPatient,
  deleteDoctorsPatient,
  addDoctorsPatient,
  resendEmailVerification,
  fetchDoctorBypass,
  updateDoctorBypass,
  doctorResetPassword,
  staffResetPassword,
  employeeResetPassword,
}
