import { createAsyncThunk } from '@reduxjs/toolkit'
import type { TablePaginationConfig } from 'antd/es/table'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import { TypeCodes } from '@/common/constants/site-selector.constants'
import api from '@/common/helpers/api.helpers'
import { IFilterRealTime } from '@/common/interfaces/filters.interface'
import { buildPaginationQuery } from '@/common/utils/table.utils'

import { IHardwareUrlParams } from '@/features/sites/interfaces/hardware.interface'

const fetchHardwares = createAsyncThunk(
  '/hardwares/fetchHardwares',
  async (payload: { tableParams: TablePaginationConfig; filters?: IFilterRealTime }) => {
    let filterParams: any = {}
    if (payload && Object.keys(payload).length) {
      if (payload.filters?.location?.length) {
        filterParams['room-id'] = payload.filters?.location.toString()
      }
      if (payload.filters?.type?.length) {
        filterParams['hardware-type'] = payload.filters?.type.toString()
      }
      if (payload.filters?.state?.length) {
        filterParams['device_state_type_code'] = payload.filters?.state.toString()
      }
    }
    const response = await api(
      HttpMethods.GET,
      buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/hardware`, payload.tableParams),
      filterParams,
    )
    return response.data
  },
)

const fetchHardwareDetails = createAsyncThunk('hardware/fetchHardwareDetails', async (params: IHardwareUrlParams) => {
  const response = await api(
    HttpMethods.GET,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/rooms/${params.roomId}/hardware/${params.hardwareId}`,
  )

  return response.data.item_details
})

const fetchSiteHardwareTemplates = createAsyncThunk('hardware/fetchSiteHardwareTemplates', async () => {
  const response = await api(
    HttpMethods.GET,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/templates/` + TypeCodes.HARDWARE_TYPE_CLASS_CODE,
    {},
  )
  return response.data
})

const fetchDynamicFields = createAsyncThunk('hardware/fetchDynamicFields', async (hardwareId: number) => {
  const response = await api(
    HttpMethods.GET,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/hardwaretypes/` + hardwareId,
    {},
  )
  return response.data.item.hardware_type_properties
})

export const siteHardwaresService = {
  fetchHardwares,
  fetchHardwareDetails,
  fetchSiteHardwareTemplates,
  fetchDynamicFields,
}
