export const KEY_SITES_SELECTOR = 'sites_selector'
export const KEY_CENTRAL_SITE = 'central-site'
export const BEVERLY_HILLS_STAGING = 'beverly-hills-staging'
export const LOS_ANGELES_STAGING = 'los-angeles-staging'
export const BEVERLY_HILLS_PRODUCTION = 'beverly-hills'
export const BEVERLY_HILLS_SECOND_PRODUCTION = 'beverly-hills-second'
export const BRENTWOOD = 'brentwood'

export const PUSHER_KEYS: Record<string, string> = {
  [BEVERLY_HILLS_STAGING]: process.env.REACT_APP_PUSHER_KEY_SITE_1 || '',
  [LOS_ANGELES_STAGING]: process.env.REACT_APP_PUSHER_KEY_SITE_2 || '',
  [BEVERLY_HILLS_PRODUCTION]: process.env.REACT_APP_PUSHER_KEY_SITE_1 || '',
  [BEVERLY_HILLS_SECOND_PRODUCTION]: process.env.REACT_APP_PUSHER_KEY_SITE_2 || '',
  [BRENTWOOD]: process.env.REACT_APP_PUSHER_KEY_SITE_3 || '',
}

export const PUSHER_CLUSTERS: Record<string, string> = {
  [BEVERLY_HILLS_STAGING]: process.env.REACT_APP_PUSHER_CLUSTER_SITE_1 || '',
  [LOS_ANGELES_STAGING]: process.env.REACT_APP_PUSHER_CLUSTER_SITE_2 || '',
  [BEVERLY_HILLS_PRODUCTION]: process.env.REACT_APP_PUSHER_CLUSTER_SITE_1 || '',
  [BEVERLY_HILLS_SECOND_PRODUCTION]: process.env.REACT_APP_PUSHER_CLUSTER_SITE_2 || '',
  [BRENTWOOD]: process.env.REACT_APP_PUSHER_CLUSTER_SITE_3 || '',
}

export enum TypeCodes {
  USER_TYPE_CLASS_CODE = 1,
  ADDRESS_TYPE_CLASS_CODE = 2,
  PAYMENT_TYPE_CLASS_CODE = 3,
  ROOM_TYPE_CLASS_CODE = 4,
  CREDIT_CARD_TYPE_CLASS_CODE = 5,
  HARDWARE_TYPE_CLASS_CODE = 6,
  STATES_TYPE_CLASS_CODE = 7,
  CONTACT_RELATIONSHIP_TYPE_CLASS_CODE = 8,
  CUSTOMER_TYPE_CLASS_CODE = 9,
  DEVICE_TYPE_CLASS_CODE = 10,
  DEVICE_TYPE_ID_CODE = 11,
  MEDIAFILE_TYPE_CLASS_CODE = 12,
  OPTION_TYPE_CLASS_CODE = 13,
  REGION_TYPE_CLASS_CODE = 14,
  OPTION_TYPE_CATEGORY_CLASS_CODE = 15,
  HARDWARE_TYPE_CATEGORY_CLASS_CODE = 16,
  FIELD_TYPE_CLASS_CODE = 17,
}
