export interface IStoreApiList<T> {
  isLoading: boolean
  isSuccess: boolean
  data: T | null
  errors: string | null
  total_items?: number
}

export class StoreApiList<T> implements IStoreApiList<T> {
  isLoading = false
  isSuccess = false
  data: T | null = null
  errors: string | null = null
  total_items = undefined
}
