import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import { ISiteLeaseDay, ISiteSchedule } from '@/features/sites/interfaces/site-building-schedule.interface'

export interface IScheduleState {
  schedules: IStoreApiList<ISiteSchedule[]>
  schedule: IStoreApiItem<ISiteSchedule>
  scheduleLeaseDays: IStoreApiList<ISiteLeaseDay[]>
}

export class ScheduleState implements IScheduleState {
  schedules: IStoreApiList<ISiteSchedule[]> = new StoreApiList()
  schedule: IStoreApiItem<ISiteSchedule> = new StoreApiItem()
  scheduleLeaseDays: IStoreApiList<ISiteLeaseDay[]> = new StoreApiList()
}
