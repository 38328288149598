import { createSlice } from '@reduxjs/toolkit'

import { ICategories } from '@/common/interfaces/categories.interface'
import { errorListData, receiveListData, requestListData } from '@/common/utils/state.utils'

import { categoriesService } from './categories.service'
import { ICategoriesState, CategoriesState } from './categories.state.interface'

const initialState = new CategoriesState()

const imagesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(categoriesService.fetchCategories.pending, (state) => {
        return requestListData<ICategoriesState, ICategories>({ ...state }, ['categories'])
      })
      .addCase(categoriesService.fetchCategories.fulfilled, (state, action) => {
        return receiveListData<ICategoriesState, ICategories>({ ...state }, ['categories'], action.payload)
      })
      .addCase(categoriesService.fetchCategories.rejected, (state, action) => {
        return errorListData<ICategoriesState, ICategories>({ ...state }, ['categories'], String(action.error.message))
      })
  },
})

export default imagesSlice.reducer
