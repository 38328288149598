import { createAsyncThunk } from '@reduxjs/toolkit'
import type { TablePaginationConfig } from 'antd/es/table'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'
import { buildPaginationQuery } from '@/common/utils/table.utils'

import { IRoom } from '@/features/rooms/interfaces/room.interface'

const baseUrl = `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/roomtypes`

const fetchRooms = createAsyncThunk('rooms/fetchRooms', async (tableParams: TablePaginationConfig) => {
  const response = await api(HttpMethods.GET, buildPaginationQuery(baseUrl, tableParams), {})
  return response.data
})

const fetchRoom = createAsyncThunk('rooms/fetchRoom', async (id: number) => {
  const response = await api(HttpMethods.GET, `${baseUrl}/${id}`, {})
  return response.data.item
})

const deleteRoom = createAsyncThunk('rooms/deleteRoom', async (id: number) => {
  const response = await api(HttpMethods.DELETE, `${baseUrl}/${id}`, {})
  return response.data
})

const updateRoom = createAsyncThunk('rooms/updateRoom', async (data: Partial<IRoom>) => {
  const response = await api(HttpMethods.PUT, `${baseUrl}/${data.id}`, data)
  return response.data
})

const addRoom = createAsyncThunk('rooms/addRoom', async (data: {}) => {
  const response = await api(HttpMethods.POST, baseUrl, data)
  return response.data
})

export const roomsService = {
  fetchRooms,
  fetchRoom,
  deleteRoom,
  updateRoom,
  addRoom,
}
