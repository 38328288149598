import { IImage } from '@/common/interfaces/image.interface'
import { ISiteSelector } from '@/common/interfaces/site-selector.interface'
import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import { ISiteSchedule } from '@/features/sites/interfaces/site-building-schedule.interface'
import {
  ICurrentCustomers,
  IPackage,
  IPackageAdd,
  IPackages,
  IPendingCustomers,
  IRoom,
  ISInventory,
  ISInventoryDetails,
  ISInventoryItemUpdate,
  ISite,
  ISiteMinInfo,
  ISProducts,
  ISProductsCreate,
  IStockAdd,
  IStockMovement,
} from '@/features/sites/interfaces/site.interface'

export interface ISiteState {
  site: IStoreApiItem<ISite>
  siteMinInfo: IStoreApiItem<ISiteMinInfo>
  sites: IStoreApiList<ISiteMinInfo[]>
  siteSelectors: IStoreApiList<ISiteSelector[]>
  siteSchedules: IStoreApiList<ISiteSchedule[]>
  siteImages: IStoreApiList<IImage[]>
  siteRooms: IStoreApiList<IRoom[]>

  productOptions: IStoreApiList<ISProducts[]>
  productOption: IStoreApiItem<ISProducts>
  productOptionsCreate: IStoreApiList<ISProductsCreate>
  productOptionsDelete: IStoreApiItem<ISProducts>
  productOptionsUpdate: IStoreApiItem<ISProducts>

  productServices: IStoreApiList<ISProducts[]>
  productService: IStoreApiItem<ISProducts>
  productServicesCreate: IStoreApiList<ISProductsCreate>
  productServiceDelete: IStoreApiList<ISProducts>
  productServiceUpdate: IStoreApiList<ISProducts>

  selectedSite?: ISiteSelector

  inventoryProducts: IStoreApiList<ISInventory[]>
  createInventoryProducts: IStoreApiList<ISInventoryItemUpdate[]>
  inventoryProductsDetails: IStoreApiItem<ISInventoryDetails>
  inventoryProductsCreateData: IStoreApiList<ISProductsCreate>
  inventoryProductsDelete: IStoreApiItem<ISInventory>
  inventoryProductsUpdate: IStoreApiItem<ISInventory>

  inventoryPackages: IStoreApiList<IPackages[]>
  inventoryPackagesCreate: IStoreApiItem<ISProductsCreate>
  inventoryPackage: IStoreApiItem<IPackage>
  inventoryPackageUpdate: IStoreApiItem<IPackage>
  inventoryPackageAdd: IStoreApiItem<IPackageAdd>
  inventoryPackageDelete: IStoreApiItem<IPackage>

  stockMovements: IStoreApiList<IStockMovement[]>
  stockMovementsAdd: IStoreApiList<IStockAdd[]>

  pendingCustomers: IStoreApiList<IPendingCustomers>
  currentCustomers: IStoreApiList<ICurrentCustomers>
}

export class SiteState implements ISiteState {
  site: IStoreApiItem<ISite> = new StoreApiItem()
  siteMinInfo: IStoreApiItem<ISiteMinInfo> = new StoreApiItem()
  sites: IStoreApiList<ISiteMinInfo[]> = new StoreApiList()
  siteSelectors: IStoreApiList<ISiteSelector[]> = new StoreApiList()
  siteSchedules: IStoreApiList<ISiteSchedule[]> = new StoreApiList()
  siteImages: IStoreApiList<IImage[]> = new StoreApiList()
  siteRooms: IStoreApiList<IRoom[]> = new StoreApiList()

  productOptions: IStoreApiList<ISProducts[]> = new StoreApiList()
  productOption: IStoreApiList<ISProducts> = new StoreApiList()
  productOptionsCreate: IStoreApiList<ISProductsCreate> = new StoreApiList()
  productOptionsDelete: IStoreApiList<ISProducts> = new StoreApiList()
  productOptionsUpdate: IStoreApiList<ISProducts> = new StoreApiList()

  productServices: IStoreApiList<ISProducts[]> = new StoreApiList()
  productService: IStoreApiList<ISProducts> = new StoreApiList()
  productServicesCreate: IStoreApiList<ISProductsCreate> = new StoreApiList()
  selectedSite?: ISiteSelector = undefined
  productServiceDelete: IStoreApiList<ISProducts> = new StoreApiList()
  productServiceUpdate: IStoreApiList<ISProducts> = new StoreApiList()

  inventoryProducts: IStoreApiList<ISInventory[]> = new StoreApiList()
  inventoryProductsDetails: IStoreApiItem<ISInventoryDetails> = new StoreApiList()
  inventoryProductsCreateData: IStoreApiList<ISProductsCreate> = new StoreApiList()
  inventoryProductsDelete: IStoreApiItem<ISInventory> = new StoreApiList()
  inventoryProductsUpdate: IStoreApiItem<ISInventory> = new StoreApiList()
  createInventoryProducts: IStoreApiList<ISInventoryItemUpdate[]> = new StoreApiList()

  inventoryPackages: IStoreApiList<IPackages[]> = new StoreApiList()
  inventoryPackagesCreate: IStoreApiItem<ISProductsCreate> = new StoreApiList()
  inventoryPackage: IStoreApiItem<IPackage> = new StoreApiList()
  inventoryPackageUpdate: IStoreApiItem<IPackage> = new StoreApiList()
  inventoryPackageAdd: IStoreApiItem<IPackageAdd> = new StoreApiList()
  inventoryPackageDelete: IStoreApiItem<IPackage> = new StoreApiList()

  stockMovements: IStoreApiList<IStockMovement[]> = new StoreApiList()
  stockMovementsAdd: IStoreApiList<IStockAdd[]> = new StoreApiList()

  currentCustomers: IStoreApiList<ICurrentCustomers> = new StoreApiList()
  pendingCustomers: IStoreApiList<IPendingCustomers> = new StoreApiList()
}
