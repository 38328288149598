import { createAsyncThunk } from '@reduxjs/toolkit'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'

const fetchSiteSchedules = createAsyncThunk('schedules/fetchSiteSchedules', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/schedule`)
  return response.data.schedules
})

const addSiteSchedule = createAsyncThunk('schedules/addSiteSchedule', async (data: {}) => {
  try {
    const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/schedule`, data)
    return response.data
  } catch (error) {
    return error
  }
})

const updateSiteSchedule = createAsyncThunk('schedules/updateSiteSchedule', async (data: any) => {
  try {
    const response = await api(
      HttpMethods.PUT,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/schedule/${data.id}`,
      data,
    )
    return response.data
  } catch (error) {
    return error
  }
})

const checkIntersection = createAsyncThunk('schedules/checkIntersection', async (scheduleId: number) => {
  try {
    const response = await api(
      HttpMethods.GET,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/schedule/intersection/${scheduleId}`,
    )
    return response.data
  } catch (error) {
    return error
  }
})

const fetchLeaseDay = createAsyncThunk('schedules/fetchLeaseDay', async (id: number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/schedule/${id}/lease-days`)
  return response.data
})

export const siteSchedulesService = {
  fetchSiteSchedules,
  addSiteSchedule,
  updateSiteSchedule,
  checkIntersection,
  fetchLeaseDay,
}
