import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import { IHardware } from '@/features/hardware/interfaces/hardware.interface'

export interface IHardwareState {
  hardwares: IStoreApiList<IHardware[]>
  hardware: IStoreApiItem<IHardware>
  addedHardware: IStoreApiItem<IHardware>
  updatedHardware: IStoreApiItem<IHardware>
  deletedHardware: IStoreApiItem<IHardware>
}

export class HardwareState implements IHardwareState {
  hardwares: IStoreApiList<IHardware[]> = new StoreApiList()
  hardware: IStoreApiItem<IHardware> = new StoreApiItem()
  addedHardware: IStoreApiItem<IHardware> = new StoreApiItem()
  updatedHardware: IStoreApiItem<IHardware> = new StoreApiItem()
  deletedHardware: IStoreApiItem<IHardware> = new StoreApiItem()
}
