import { createSlice } from '@reduxjs/toolkit'

import {
  errorItemData,
  errorPostPutItemData,
  receiveItemData,
  receivePostPutItemData,
  requestItemData,
  requestPostPutItemData,
} from '@/common/utils/state.utils'

import { IProfile, IProfileImage, IProfileUpdate } from 'src/features/settings/interfaces/settings.interface'

import { settingsService } from './settings.service'
import { SettingsState } from './settings.state.interface'

const initialState = new SettingsState()

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      /// get profile
      .addCase(settingsService.fetchProfile.pending, (state) => {
        return requestItemData<SettingsState, IProfile>({ ...state }, ['profile'])
      })
      .addCase(settingsService.fetchProfile.fulfilled, (state, action) => {
        return receiveItemData<SettingsState, IProfile>({ ...state }, ['profile'], action.payload)
      })
      .addCase(settingsService.fetchProfile.rejected, (state, action) => {
        return errorItemData<SettingsState, IProfile>({ ...state }, ['profile'], String(action.error.message))
      })

      /// update profile
      .addCase(settingsService.updateProfile.pending, (state) => {
        return requestPostPutItemData<SettingsState, IProfileUpdate>({ ...state }, ['profileUpdate'])
      })
      .addCase(settingsService.updateProfile.fulfilled, (state, action) => {
        return receivePostPutItemData<SettingsState, IProfileUpdate>({ ...state }, ['profileUpdate'], action.payload)
      })
      .addCase(settingsService.updateProfile.rejected, (state, action) => {
        return errorPostPutItemData<SettingsState, IProfileUpdate>(
          { ...state },
          ['profileUpdate'],
          String(action.error.message),
        )
      })

      /// get profile image
      .addCase(settingsService.fetchProfileImage.pending, (state) => {
        return requestItemData<SettingsState, IProfileImage>({ ...state }, ['profileImage'])
      })
      .addCase(settingsService.fetchProfileImage.fulfilled, (state, action) => {
        return receiveItemData<SettingsState, IProfileImage>({ ...state }, ['profileImage'], action.payload)
      })
      .addCase(settingsService.fetchProfileImage.rejected, (state, action) => {
        return errorItemData<SettingsState, IProfileImage>({ ...state }, ['profileImage'], String(action.error.message))
      })
  },
})

export default settingsSlice.reducer
