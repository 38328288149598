import { createSlice } from '@reduxjs/toolkit'

import {
  errorDeleteItem,
  errorListData,
  errorPostPutItemData,
  receiveDeleteItem,
  receiveListData,
  receivePostPutItemData,
  requestDeleteItem,
  requestListData,
  requestPostPutItemData,
} from '@/common/utils/state.utils'

import { ILevel } from '@/features/levels/interfaces/level.interface'

import { levelsService } from './levels.service'
import { ILevelState, LevelState } from './levels.state.interface'

const initialState = new LevelState()

const levelsSlice = createSlice({
  name: 'levels',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(levelsService.fetchLevels.pending, (state) => {
        return requestListData<ILevelState, ILevel[]>({ ...state }, ['levels'])
      })
      .addCase(levelsService.fetchLevels.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<ILevelState, ILevel[]>({ ...state }, ['levels'], items, total_items)
      })
      .addCase(levelsService.fetchLevels.rejected, (state, action) => {
        return errorListData<ILevelState, ILevel[]>({ ...state }, ['levels'], String(action.error.message))
      })
      .addCase(levelsService.updateLevel.pending, (state) => {
        return requestPostPutItemData<ILevelState, ILevel>({ ...state }, ['level'])
      })
      .addCase(levelsService.updateLevel.fulfilled, (state, action) => {
        return receivePostPutItemData<ILevelState, ILevel>({ ...state }, ['level'], action.payload)
      })
      .addCase(levelsService.updateLevel.rejected, (state, action) => {
        return errorPostPutItemData<ILevelState, ILevel>({ ...state }, ['level'], String(action.error.message))
      })
      .addCase(levelsService.deleteLevel.pending, (state) => {
        return requestDeleteItem<ILevelState, ILevel>({ ...state }, ['level'])
      })
      .addCase(levelsService.deleteLevel.fulfilled, (state) => {
        return receiveDeleteItem<ILevelState, ILevel>({ ...state }, ['level'])
      })
      .addCase(levelsService.deleteLevel.rejected, (state, action) => {
        return errorDeleteItem<ILevelState, ILevel>({ ...state }, ['level'], String(action.error.message))
      })
      .addCase(levelsService.addLevel.pending, (state) => {
        return requestPostPutItemData<ILevelState, ILevel>({ ...state }, ['level'])
      })
      .addCase(levelsService.addLevel.fulfilled, (state, action) => {
        return receivePostPutItemData<ILevelState, ILevel>({ ...state }, ['level'], action.payload)
      })
      .addCase(levelsService.addLevel.rejected, (state, action) => {
        return errorPostPutItemData<ILevelState, ILevel>({ ...state }, ['level'], String(action.error.message))
      })
  },
})

export default levelsSlice.reducer
