export enum UPLOAD_CONSTANTS {
  SELECT_FILE = 'Select a file',
  DRAG_AND_DROP = ' or drag and drop here',
  UPLOAD = 'Upload',
  PREVIEW = 'Preview',
  FILE_DESCRIPTION = 'JPG, PNG or PDF, file size no more than 10MB',
  UNSUPPORTED_FILE_TYPE = 'Unsupported file type',
}

export const DOCUMENT_UPLOAD_FILE_TYPES = {
  JPG_FILE: 'image/jpeg',
  PNG_FILE: 'image/png',
  GIF_FILE: 'image/gif',
  PDF_FILE: 'application/pdf',
}

export const IMAGE_UPLOAD_FILE_TYPES = {
  JPG_FILE: 'image/jpeg',
  PNG_FILE: 'image/png',
  GIF_FILE: 'image/gif',
}
