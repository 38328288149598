import { createAsyncThunk } from '@reduxjs/toolkit'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'

import { IAuth, ILogin, IResetPassword } from '@/features/auth/interfaces/auth.interface'

const handleLogin = createAsyncThunk('auth/login', async (data: ILogin, { rejectWithValue }) => {
  try {
    const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/login`, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err)
  }
})

const handleForgotPassword = createAsyncThunk('auth/forgot-password', async (data: IAuth, { rejectWithValue }) => {
  try {
    const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/forgot-password`, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err)
  }
})

const handleResetPassword = createAsyncThunk(
  'auth/reset-password',
  async (data: IResetPassword, { rejectWithValue }) => {
    try {
      const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/reset-password`, data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const authService = {
  handleLogin,
  handleForgotPassword,
  handleResetPassword,
}
