import { createSlice } from '@reduxjs/toolkit'

import {
  errorDeleteItem,
  errorListData,
  errorPostPutItemData,
  receiveDeleteItem,
  receiveListData,
  receivePostPutItemData,
  requestDeleteItem,
  requestListData,
  requestPostPutItemData,
} from '@/common/utils/state.utils'

import { IRoom } from '@/features/rooms/interfaces/room.interface'

import { roomsService } from './rooms.service'
import { IRoomState, RoomState } from './rooms.state.interface'

const initialState = new RoomState()

const roomsSlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(roomsService.fetchRooms.pending, (state) => {
        return requestListData<IRoomState, IRoom[]>({ ...state }, ['rooms'])
      })
      .addCase(roomsService.fetchRooms.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<IRoomState, IRoom[]>({ ...state }, ['rooms'], items, total_items)
      })
      .addCase(roomsService.fetchRooms.rejected, (state, action) => {
        return errorListData<IRoomState, IRoom[]>({ ...state }, ['rooms'], String(action.error.message))
      })
      .addCase(roomsService.fetchRoom.pending, (state) => {
        return requestListData<IRoomState, IRoom>({ ...state }, ['room'])
      })
      .addCase(roomsService.fetchRoom.fulfilled, (state, action) => {
        return receiveListData<IRoomState, IRoom>({ ...state }, ['room'], action.payload)
      })
      .addCase(roomsService.fetchRoom.rejected, (state, action) => {
        return errorListData<IRoomState, IRoom>({ ...state }, ['room'], String(action.error.message))
      })

      .addCase(roomsService.addRoom.pending, (state) => {
        return requestPostPutItemData<IRoomState, IRoom>({ ...state }, ['roomAdded'])
      })
      .addCase(roomsService.addRoom.fulfilled, (state, action) => {
        return receivePostPutItemData<IRoomState, IRoom>({ ...state }, ['roomAdded'], action.payload)
      })
      .addCase(roomsService.addRoom.rejected, (state, action) => {
        return errorPostPutItemData<IRoomState, IRoom>({ ...state }, ['roomAdded'], String(action.error.message))
      })

      .addCase(roomsService.updateRoom.pending, (state) => {
        return requestPostPutItemData<IRoomState, IRoom>({ ...state }, ['roomUpdated'])
      })
      .addCase(roomsService.updateRoom.fulfilled, (state, action) => {
        return receivePostPutItemData<IRoomState, IRoom>({ ...state }, ['roomUpdated'], action.payload)
      })
      .addCase(roomsService.updateRoom.rejected, (state, action) => {
        return errorPostPutItemData<IRoomState, IRoom>({ ...state }, ['roomUpdated'], String(action.error.message))
      })

      .addCase(roomsService.deleteRoom.pending, (state) => {
        return requestDeleteItem<IRoomState, IRoom>({ ...state }, ['roomDeleted'])
      })
      .addCase(roomsService.deleteRoom.fulfilled, (state) => {
        return receiveDeleteItem<IRoomState, IRoom>({ ...state }, ['roomDeleted'])
      })
      .addCase(roomsService.deleteRoom.rejected, (state, action) => {
        return errorDeleteItem<IRoomState, IRoom>({ ...state }, ['roomDeleted'], String(action.error.message))
      })
  },
})

export default roomsSlice.reducer
