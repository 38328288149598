import { ICodeName } from '@/common/interfaces/code-name.interface'
import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import {
  IRoom,
  IRoomHardwareDetails,
  IRoomHardwareItem,
  IRoomsLeases,
} from '@/features/sites/interfaces/site.interface'

export interface ISiteRoomState {
  room: IStoreApiItem<IRoom>
  rooms: IStoreApiList<IRoom[]>
  roomTypes: IStoreApiList<ICodeName[]>
  roomHardwareDetails: IStoreApiItem<IRoomHardwareDetails>
  roomHardwareCombine: IRoomHardwareItem[]
  roomsLeases: IStoreApiList<IRoomsLeases[]>
  roomsWithOptions: IStoreApiList<IRoomsLeases[]>
  allHardwares: IStoreApiList<IRoomHardwareItem[]>
}

export class SiteRoomState implements ISiteRoomState {
  room: IStoreApiItem<IRoom> = new StoreApiItem()
  rooms: IStoreApiList<IRoom[]> = new StoreApiList()
  roomTypes: IStoreApiList<ICodeName[]> = new StoreApiList()
  roomHardwareDetails: IStoreApiItem<IRoomHardwareDetails> = new StoreApiList()
  roomHardwareCombine: IRoomHardwareItem[] = []
  roomsLeases: IStoreApiList<IRoomsLeases[]> = new StoreApiList()
  roomsWithOptions: IStoreApiList<IRoomsLeases[]> = new StoreApiList()
  allHardwares: IStoreApiList<IRoomHardwareItem[]> = new StoreApiList()
}
