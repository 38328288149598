import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import { ITranslation, ITranslations } from 'src/features/translations/interfaces/translate.interface'

export interface ITranslationState {
  translations: IStoreApiList<ITranslations>
  translation: IStoreApiItem<ITranslation>
}

export class TranslationState implements ITranslationState {
  translations: IStoreApiList<ITranslations> = new StoreApiList()
  translation: IStoreApiItem<ITranslation> = new StoreApiItem()
}
