import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'

import { IHardware, IHardwareFilterData, IRoomNewHardware } from '@/features/sites/interfaces/hardware.interface'

export interface IHardwareState {
  siteHardwares: IStoreApiItem<IHardwareFilterData>
  hardwareDetails: IStoreApiItem<IRoomNewHardware>
  hardwareUpdate: IStoreApiItem<IRoomNewHardware>
  hardwareCreate: IStoreApiItem<IRoomNewHardware>
  hardwareTemplates: IStoreApiItem<IHardware>
  dynamicFields: IStoreApiItem<[]>
}

export class HardwareState implements IHardwareState {
  siteHardwares: IStoreApiItem<IHardwareFilterData> = new StoreApiItem()
  hardwareDetails: IStoreApiItem<IRoomNewHardware> = new StoreApiItem()
  hardwareUpdate: IStoreApiItem<IRoomNewHardware> = new StoreApiItem()
  hardwareCreate: IStoreApiItem<IRoomNewHardware> = new StoreApiItem()
  hardwareTemplates: IStoreApiItem<IHardware> = new StoreApiItem()
  dynamicFields: IStoreApiItem<[]> = new StoreApiItem()
}
