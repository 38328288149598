import { combineReducers, configureStore } from '@reduxjs/toolkit'

import appSlice from '@/common/state/app/app.slice'
import categoriesSlice from '@/common/state/categories/categories.slice'
import filtersSlice from '@/common/state/filters/filters.slice'
import permissionsSlice from '@/common/state/permissions/permissions.slice'
import typesSlice from '@/common/state/types/types.slice'

import authSlice from '@/features/auth/state/auth.slice'
import faqSlice from '@/features/faq/state/faq.slice'
import hardwaresSlice from '@/features/hardware/state/hardwares.slice'
import homeSlice from '@/features/home/state/home.slice'
import leasesSlice from '@/features/leases/state/leases.slice'
import levelsSlice from '@/features/levels/state/levels.slice'
import mediaSlice from '@/features/media/state/media.slice'
import optionsSlice from '@/features/options/state/options.slice'
import promotionsSlice from '@/features/promotions/state/promotions.slice'
import roomsSlice from '@/features/rooms/state/rooms.slice'
import settingsSlice from '@/features/settings/state/settings.slice'
import siteHardwaresSlice from '@/features/sites/state/hardwares/siteHardwares.slice'
import sitePricesSlice from '@/features/sites/state/prices/sitePrices.slice'
import siteRoomsSlice from '@/features/sites/state/rooms/siteRooms.slice'
import siteSchedulesSlice from '@/features/sites/state/schedules/siteSchedules.slice'
import sitesSlice from '@/features/sites/state/sites/sites.slice'
import taxesSlice from '@/features/sites/state/taxes/taxes.slice'
import supportSlice from '@/features/support/state/support.slice'
import conditionSlice from '@/features/terms/state/condition/condition.slice'
import dictionarySlice from '@/features/terms/state/dictionary/dictionary.slice'
import translationsSlice from '@/features/translations/state/translations.slice'
import usersSlice from '@/features/users/state/users.slice'

const rootReducer = combineReducers({
  auth: authSlice,
  home: homeSlice,
  site: sitesSlice,
  taxes: taxesSlice,
  settings: settingsSlice,
  siteRooms: siteRoomsSlice,
  rooms: roomsSlice,
  hardwares: hardwaresSlice,
  options: optionsSlice,
  categories: categoriesSlice,
  types: typesSlice,
  support: supportSlice,
  siteHardwares: siteHardwaresSlice,
  filters: filtersSlice,
  schedules: siteSchedulesSlice,
  prices: sitePricesSlice,
  levels: levelsSlice,
  translations: translationsSlice,
  leases: leasesSlice,
  users: usersSlice,
  faq: faqSlice,
  dictionaries: dictionarySlice,
  condition: conditionSlice,
  media: mediaSlice,
  permissions: permissionsSlice,
  app: appSlice,
  promotions: promotionsSlice,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
