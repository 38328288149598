import { createAsyncThunk } from '@reduxjs/toolkit'
import { TablePaginationConfig } from 'antd'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'
import { buildPaginationQuery } from '@/common/utils/table.utils'

const fetchDoctorsLeasesRealTime = createAsyncThunk(
  'home/fetchDoctorsLeasesRealTime',
  async ({ id, date }: { id: string | number; date?: string }) => {
    const response = await api(
      HttpMethods.GET,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${id}/real-time?date=${date}`,
    )
    return response.data
  },
)

const fetchTvsAndTablets = createAsyncThunk('home/fetchTvsAndTablets', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/tv-and-tablets`)
  return response.data
})

const restartTvsAndTablets = createAsyncThunk('home/restartTvsAndTablets', async (id: number) => {
  const response = await api(
    HttpMethods.POST,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/tv-and-tablets/restart-device/${id}`,
  )
  return response.data
})

const fetchIpCameras = createAsyncThunk('home/fetchIpCameras', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/ip-cameras`)
  return response.data
})

const rotateAllCameras = createAsyncThunk('home/rotateAllCameras', async () => {
  const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/ip-cameras/rotate-all`)
  return response.data
})

const rotateCamera = createAsyncThunk('home/rotateCamera', async (id: number) => {
  const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/ip-cameras/rotate/${id}`)
  return response.data
})

const fetchSiteStatistics = createAsyncThunk('home/fetchSiteStatistics', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/site-statistics`)
  return response.data
})

const updateStatistics = createAsyncThunk('home/updateStatistics', async (data: { name: string; value: string }) => {
  const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/site-statistics`, data)
  return response.data
})

/// LOCK DOORS PAGE
const fetchDoors = createAsyncThunk('home/fetchDoors', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/door`)
  return response.data
})

const toggleDoor = createAsyncThunk('home/toggleDoor', async (data: { id: number; device_state: number }) => {
  const response = await api(
    HttpMethods.PUT,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/door/turn-on-or-off/${data.id}`,
    { device_state: data.device_state },
  )
  return response.data
})

const toggleAllDoors = createAsyncThunk('home/toggleAllDoors', async (open_all_doors: number) => {
  const response = await api(
    HttpMethods.PUT,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/door/open-or-close-all`,
    { open_all_doors },
  )
  return response.data
})

/// MAILBOX PAGE
const fetchMailboxes = createAsyncThunk('home/fetchMailboxes', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/mailbox`)
  return response.data
})

const toggleMailbox = createAsyncThunk('home/toggleMailbox', async (data: { id: number; device_state: number }) => {
  const response = await api(
    HttpMethods.PUT,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/mailbox/turn-on-or-off/${data.id}`,
    { device_state: data.device_state },
  )
  return response.data
})

const toggleAllMailboxes = createAsyncThunk('home/toggleAllMailboxes', async (open_all_doors: number) => {
  const response = await api(
    HttpMethods.PUT,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/mailbox/open-or-close-all`,
    { open_all_doors },
  )
  return response.data
})

const notifyMailboxDoctor = createAsyncThunk(
  'home/notifyMailboxDoctor',
  async (data: { doctor_id?: number; message?: string; identifier?: string }) => {
    const response = await api(
      HttpMethods.PUT,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/mailbox/notify`,
      data,
    )
    return response.data
  },
)

const fetchDoctorsLocks = createAsyncThunk('home/fetchDoctorsLocks', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/mailbox/doctors`)
  return response.data
})

/// LOGS PAGE
const fetchLogs = createAsyncThunk(
  'home/fetchLogs',
  async ({ searchParams = '', tableParams }: { searchParams?: string; tableParams: TablePaginationConfig }) => {
    const response = await api(
      HttpMethods.GET,
      `${buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/logs`, tableParams)}${
        searchParams ? '&' + searchParams : ''
      }`,
    )
    return response.data
  },
)

///LOCKERS PAGE
const fetchLockers = createAsyncThunk('home/fetchLockers', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/locker`)
  return response.data
})

const toggleLocker = createAsyncThunk('home/toggleLocker', async (data: { id: number; device_state: number }) => {
  const response = await api(
    HttpMethods.PUT,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/locker/turn-on-or-off/${data.id}`,
    { device_state: data.device_state },
  )
  return response.data
})

const toggleAllLockers = createAsyncThunk(
  'home/toggleAllLockers',
  async (data: { open_all_doors: number; locker_type: 'full' | 'half' }) => {
    const response = await api(
      HttpMethods.PUT,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/locker/open-or-close-all`,
      data,
    )
    return response.data
  },
)

///CABINETS PAGE
const fetchCabinets = createAsyncThunk('home/fetchCabinets', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/cabinet`)
  return response.data
})

const toggleCabinet = createAsyncThunk('home/toggleCabinet', async (data: { id: number; device_state: number }) => {
  const response = await api(
    HttpMethods.PUT,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/cabinet/turn-on-or-off/${data.id}`,
    { device_state: data.device_state },
  )
  return response.data
})

const toggleAllCabinets = createAsyncThunk('home/toggleAllCabinets', async (open_all_doors: number) => {
  const response = await api(
    HttpMethods.PUT,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/cabinet/open-or-close-all`,
    { open_all_doors },
  )
  return response.data
})

///FRIDGE PAGE
const fetchFridges = createAsyncThunk('home/fetchFridges', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/fridge`)
  return response.data
})

const toggleFridge = createAsyncThunk('home/toggleFridge', async (data: { id: number; device_state: number }) => {
  const response = await api(
    HttpMethods.PUT,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/fridge/turn-on-or-off/${data.id}`,
    { device_state: data.device_state },
  )
  return response.data
})

const toggleAllFridges = createAsyncThunk('home/toggleAllFridges', async (open_all_doors: number) => {
  const response = await api(
    HttpMethods.PUT,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/fridge/open-or-close-all`,
    { open_all_doors },
  )
  return response.data
})

///MEDICAL CART DOCKS PAGE
const fetchMedicalCartDocks = createAsyncThunk('home/fetchMedicalCartDocks', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/mobile-cart-docks`)
  return response.data
})

const toggleMedicalCart = createAsyncThunk(
  'home/toggleMedicalCart',
  async (data: { id: number; device_state: number }) => {
    const response = await api(
      HttpMethods.PUT,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/mobile-cart-docks/turn-on-or-off/${data.id}`,
      { device_state: data.device_state },
    )
    return response.data
  },
)

const toggleAllMedicalCarts = createAsyncThunk('home/toggleAllMedicalCarts', async (open_all_doors: number) => {
  const response = await api(
    HttpMethods.PUT,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/locks/mobile-cart-docks/open-or-close-all`,
    { open_all_doors },
  )
  return response.data
})

const fetchMobileCarts = createAsyncThunk('home/fetchMobileCarts', async (params?: string) => {
  let baseUrl = `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/mobile-medical-carts`
  if (params) {
    baseUrl += params
  }
  const response = await api(HttpMethods.GET, baseUrl)
  return response.data
})

export const homeService = {
  fetchDoctorsLeasesRealTime,
  fetchSiteStatistics,
  updateStatistics,
  fetchIpCameras,
  rotateAllCameras,
  rotateCamera,
  fetchDoors,
  toggleDoor,
  toggleAllDoors,
  fetchTvsAndTablets,
  restartTvsAndTablets,
  fetchLogs,
  fetchMailboxes,
  toggleMailbox,
  toggleAllMailboxes,
  fetchDoctorsLocks,
  notifyMailboxDoctor,
  fetchLockers,
  toggleLocker,
  toggleAllLockers,
  fetchCabinets,
  toggleCabinet,
  toggleAllCabinets,
  fetchFridges,
  toggleFridge,
  toggleAllFridges,
  fetchMedicalCartDocks,
  toggleAllMedicalCarts,
  toggleMedicalCart,
  fetchMobileCarts,
}
