import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import { ISupportTicket, ITicket } from '@/features/support/interfaces/support.interface'

export interface ISupportState {
  tickets: IStoreApiList<ISupportTicket>
  ticketDetails: IStoreApiItem<ITicket>
  addTicketMessageStatus: IStoreApiItem<ITicket>
  updateTicketStatus: IStoreApiItem<ITicket>
}

export class SupportState implements ISupportState {
  tickets: IStoreApiList<ISupportTicket> = new StoreApiList()
  ticketDetails: IStoreApiItem<ITicket> = new StoreApiItem()
  addTicketMessageStatus: IStoreApiItem<any> = new StoreApiItem()
  updateTicketStatus: IStoreApiItem<any> = new StoreApiItem()
}
