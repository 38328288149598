import { createSlice } from '@reduxjs/toolkit'

import {
  errorListData,
  errorPostPutItemData,
  receiveListData,
  receivePostPutItemData,
  requestListData,
  requestPostPutItemData,
} from '@/common/utils/state.utils'

import {
  ITranslation,
  ITranslationResponse,
  ITranslations,
} from 'src/features/translations/interfaces/translate.interface'

import { translationsService } from './translations.service'
import { ITranslationState, TranslationState } from './translations.state.interface'

const initialState = new TranslationState()

const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(translationsService.fetchTranslations.pending, (state) => {
        return requestListData<ITranslationState, ITranslations>({ ...state }, ['translations'])
      })
      .addCase(translationsService.fetchTranslations.fulfilled, (state, action) => {
        const { total_items, ...rest } = action.payload
        return receiveListData<ITranslationState, ITranslations>({ ...state }, ['translations'], rest, total_items)
      })
      .addCase(translationsService.fetchTranslations.rejected, (state, action) => {
        return errorListData<ITranslationState, ITranslations>(
          { ...state },
          ['translations'],
          String(action.error.message),
        )
      })
      .addCase(translationsService.fetchTranslation.pending, (state) => {
        return requestListData<ITranslationState, ITranslationResponse>({ ...state }, ['translation'])
      })
      .addCase(translationsService.fetchTranslation.fulfilled, (state, action) => {
        return receiveListData<ITranslationState, ITranslationResponse>({ ...state }, ['translation'], action.payload)
      })
      .addCase(translationsService.fetchTranslation.rejected, (state, action) => {
        return errorListData<ITranslationState, ITranslationResponse>(
          { ...state },
          ['translation'],
          String(action.error.message),
        )
      })
      .addCase(translationsService.addTranslation.pending, (state) => {
        return requestPostPutItemData<ITranslationState, ITranslation>({ ...state }, ['translations'])
      })
      .addCase(translationsService.addTranslation.fulfilled, (state, action) => {
        return receivePostPutItemData<ITranslationState, ITranslation>({ ...state }, ['translations'], action.payload)
      })
      .addCase(translationsService.addTranslation.rejected, (state, action) => {
        return errorPostPutItemData<ITranslationState, ITranslation>(
          { ...state },
          ['translations'],
          String(action.error.message),
        )
      })
      .addCase(translationsService.addTranslationVariables.pending, (state) => {
        return requestPostPutItemData<ITranslationState, ITranslation>({ ...state }, ['translations'])
      })
      .addCase(translationsService.addTranslationVariables.fulfilled, (state, action) => {
        return receivePostPutItemData<ITranslationState, ITranslation>({ ...state }, ['translations'], action.payload)
      })
      .addCase(translationsService.addTranslationVariables.rejected, (state, action) => {
        return errorPostPutItemData<ITranslationState, ITranslation>(
          { ...state },
          ['translations'],
          String(action.error.message),
        )
      })
      .addCase(translationsService.updateTranslation.pending, (state) => {
        return requestPostPutItemData<ITranslationState, ITranslation>({ ...state }, ['translations'])
      })
      .addCase(translationsService.updateTranslation.fulfilled, (state, action) => {
        return receivePostPutItemData<ITranslationState, ITranslation>({ ...state }, ['translations'], action.payload)
      })
      .addCase(translationsService.updateTranslation.rejected, (state, action) => {
        return errorPostPutItemData<ITranslationState, ITranslation>(
          { ...state },
          ['translations'],
          String(action.error.message),
        )
      })
  },
})

export default translationsSlice.reducer
