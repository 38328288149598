import { createSlice } from '@reduxjs/toolkit'

import {
  errorItemData,
  errorPostPutItemData,
  receiveItemData,
  receivePostPutItemData,
  requestItemData,
  requestPostPutItemData,
} from '@/common/utils/state.utils'

import { ICondition } from 'src/features/terms/interfaces/terms.interface'

import { conditionService } from './condition.service'
import { ConditionState, IConditionState } from './condition.state.interface'

const initialState = new ConditionState()

const conditionSlice = createSlice({
  name: 'condition',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(conditionService.fetchCondition.pending, (state) => {
        return requestItemData<IConditionState, ICondition>({ ...state }, ['condition'])
      })
      .addCase(conditionService.fetchCondition.fulfilled, (state, action) => {
        return receiveItemData<IConditionState, ICondition>({ ...state }, ['condition'], action.payload)
      })
      .addCase(conditionService.fetchCondition.rejected, (state, action) => {
        return errorItemData<IConditionState, ICondition>({ ...state }, ['condition'], String(action.error.message))
      })
      .addCase(conditionService.updateCondition.pending, (state) => {
        return requestPostPutItemData<IConditionState, ICondition>({ ...state }, ['conditionUpdate'])
      })
      .addCase(conditionService.updateCondition.fulfilled, (state, action) => {
        return receivePostPutItemData<IConditionState, ICondition>({ ...state }, ['conditionUpdate'], action.payload)
      })
      .addCase(conditionService.updateCondition.rejected, (state, action) => {
        return errorPostPutItemData<IConditionState, ICondition>(
          { ...state },
          ['conditionUpdate'],
          String(action.error.message),
        )
      })
  },
})

export default conditionSlice.reducer
