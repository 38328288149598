import { IUserPermissions } from '@/common/interfaces/permissions.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

export interface IPermissionsState {
  permissions: IStoreApiList<IUserPermissions>
}

export class PermissionsState implements IPermissionsState {
  permissions: IStoreApiList<IUserPermissions> = new StoreApiList()
}
