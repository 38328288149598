export interface IStoreApiItem<T> {
  isLoading: boolean
  data: T | null
  errors: string | null
  isSuccess: boolean
  total_items?: number | undefined
}

export class StoreApiItem<T> implements IStoreApiItem<T> {
  isLoading = false
  data: T | null = null
  errors: string | null = null
  isSuccess = false
  total_items = undefined
}
