import { createSlice } from '@reduxjs/toolkit'

import { errorItemData, receiveItemData, requestItemData } from '@/common/utils/state.utils'

import { IAuth, ILogin, IResetPassword } from 'src/features/auth/interfaces/auth.interface'

import { authService } from './auth.service'
import { AuthState } from './auth.state.interface'

const initialState = new AuthState()

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      ///login
      .addCase(authService.handleLogin.pending, (state) => {
        return requestItemData<AuthState, ILogin>({ ...state }, ['login'])
      })
      .addCase(authService.handleLogin.fulfilled, (state, action) => {
        return receiveItemData<AuthState, ILogin>({ ...state }, ['login'], action.payload)
      })
      .addCase(authService.handleLogin.rejected, (state, action) => {
        return errorItemData<AuthState, ILogin>({ ...state }, ['login'], String(action.error.message))
      })

      ///forgot password
      .addCase(authService.handleForgotPassword.pending, (state) => {
        return requestItemData<AuthState, IAuth>({ ...state }, ['forgotPassword'])
      })
      .addCase(authService.handleForgotPassword.fulfilled, (state, action) => {
        return receiveItemData<AuthState, IAuth>({ ...state }, ['forgotPassword'], action.payload)
      })
      .addCase(authService.handleForgotPassword.rejected, (state, action) => {
        return errorItemData<AuthState, IAuth>({ ...state }, ['forgotPassword'], String(action.error.message))
      })

      ///set new password
      .addCase(authService.handleResetPassword.pending, (state) => {
        return requestItemData<AuthState, IResetPassword>({ ...state }, ['resetPassword'])
      })
      .addCase(authService.handleResetPassword.fulfilled, (state, action) => {
        return receiveItemData<AuthState, IResetPassword>({ ...state }, ['resetPassword'], action.payload)
      })
      .addCase(authService.handleResetPassword.rejected, (state, action) => {
        return errorItemData<AuthState, IResetPassword>({ ...state }, ['resetPassword'], String(action.error.message))
      })
  },
})

export default authSlice.reducer
