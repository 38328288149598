import { createSlice } from '@reduxjs/toolkit'

import {
  errorDeleteItem,
  errorItemData,
  errorListData,
  errorPostPutItemData,
  receiveDeleteItem,
  receiveItemData,
  receiveListData,
  receivePostPutItemData,
  requestDeleteItem,
  requestItemData,
  requestListData,
  requestPostPutItemData,
} from '@/common/utils/state.utils'

import { IOption, IOptionResponse, PriceIntervalsResponse } from '@/features/options/interfaces/option.interface'

import { optionsService } from './options.service'
import { IOptionState, OptionState } from './options.state.interface'

const initialState = new OptionState()

const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(optionsService.fetchOptions.pending, (state) => {
        return requestListData<IOptionState, IOption[]>({ ...state }, ['options'])
      })
      .addCase(optionsService.fetchOptions.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<IOptionState, IOption[]>({ ...state }, ['options'], items, total_items)
      })
      .addCase(optionsService.fetchOptions.rejected, (state, action) => {
        return errorListData<IOptionState, IOption[]>({ ...state }, ['options'], String(action.error.message))
      })
      .addCase(optionsService.fetchOption.pending, (state) => {
        return requestListData<IOptionState, IOptionResponse>({ ...state }, ['option'])
      })
      .addCase(optionsService.fetchOption.fulfilled, (state, action) => {
        return receiveListData<IOptionState, IOptionResponse>({ ...state }, ['option'], action.payload)
      })
      .addCase(optionsService.fetchOption.rejected, (state, action) => {
        return errorListData<IOptionState, IOptionResponse>({ ...state }, ['option'], String(action.error.message))
      })
      .addCase(optionsService.updateOption.pending, (state) => {
        return requestPostPutItemData<IOptionState, IOption>({ ...state }, ['option'])
      })
      .addCase(optionsService.updateOption.fulfilled, (state, action) => {
        return receivePostPutItemData<IOptionState, IOption>({ ...state }, ['option'], action.payload)
      })
      .addCase(optionsService.updateOption.rejected, (state, action) => {
        return errorPostPutItemData<IOptionState, IOption>({ ...state }, ['option'], String(action.error.message))
      })
      .addCase(optionsService.addOption.pending, (state) => {
        return requestPostPutItemData<IOptionState, IOption>({ ...state }, ['option'])
      })
      .addCase(optionsService.addOption.fulfilled, (state, action) => {
        return receivePostPutItemData<IOptionState, IOption>({ ...state }, ['option'], action.payload)
      })
      .addCase(optionsService.addOption.rejected, (state, action) => {
        return errorPostPutItemData<IOptionState, IOption>({ ...state }, ['option'], String(action.error.message))
      })
      .addCase(optionsService.deleteOption.pending, (state) => {
        return requestDeleteItem<IOptionState, IOption>({ ...state }, ['option'])
      })
      .addCase(optionsService.deleteOption.fulfilled, (state) => {
        return receiveDeleteItem<IOptionState, IOption>({ ...state }, ['option'])
      })
      .addCase(optionsService.deleteOption.rejected, (state, action) => {
        return errorDeleteItem<IOptionState, IOption>({ ...state }, ['option'], String(action.error.message))
      })
      .addCase(optionsService.optionPriceIntervals.pending, (state) => {
        return requestItemData<IOptionState, PriceIntervalsResponse>({ ...state }, ['optionsPriceIntervals'])
      })
      .addCase(optionsService.optionPriceIntervals.fulfilled, (state, action) => {
        return receiveItemData<IOptionState, PriceIntervalsResponse>(
          { ...state },
          ['optionsPriceIntervals'],
          action.payload,
        )
      })
      .addCase(optionsService.optionPriceIntervals.rejected, (state, action) => {
        return errorItemData<IOptionState, PriceIntervalsResponse>(
          { ...state },
          ['optionsPriceIntervals'],
          String(action.error.message),
        )
      })

      .addCase(optionsService.optionPriceIntervalsForCategory.pending, (state) => {
        return requestItemData<IOptionState, PriceIntervalsResponse>({ ...state }, ['optionPriceIntervalsForCategory'])
      })
      .addCase(optionsService.optionPriceIntervalsForCategory.fulfilled, (state, action) => {
        return receiveItemData<IOptionState, PriceIntervalsResponse>(
          { ...state },
          ['optionPriceIntervalsForCategory'],
          action.payload,
        )
      })
      .addCase(optionsService.optionPriceIntervalsForCategory.rejected, (state, action) => {
        return errorItemData<IOptionState, PriceIntervalsResponse>(
          { ...state },
          ['optionPriceIntervalsForCategory'],
          String(action.error.message),
        )
      })
  },
})

export default optionsSlice.reducer
