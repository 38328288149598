/* eslint-disable no-relative-import-paths/no-relative-import-paths */
import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, Method } from 'axios'

import { LocalStorageKeys } from '@/common/constants/localstorage.constants'

import { store } from '../../store/store'

export const http = axios.create({
  responseType: 'json',
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export default async function api(
  method: Method,
  url: string,
  params: unknown | Record<string, unknown> | FormData = {},
  config: AxiosRequestConfig = {},
  signal?: AbortSignal,
): Promise<AxiosResponse<any>> {
  try {
    const body = method === 'GET' ? 'params' : 'data'
    const requestConfig: AxiosRequestConfig = {
      method,
      url,
      [body]: params,
      ...config,
      signal,
    }
    http.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(
      String(localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)),
    )}`
    return http.request<ResponseType>(requestConfig)
  } catch (error) {
    throw error
  }
}

http.interceptors.request.use(
  (req: AxiosRequestConfig) => {
    const site = store.getState().site.selectedSite?.code

    const adaptedReq = { ...req }
    if (site) {
      ;(adaptedReq.headers as AxiosRequestHeaders)['Database-Connection'] = site
    }
    return adaptedReq
  },
  (err: AxiosError) => {
    return Promise.reject(err)
  },
)

http.interceptors.response.use(
  (res: AxiosResponse) => {
    return res
  },
  (err: AxiosError) => {
    switch (err.response?.status) {
      case 401:
        localStorage.clear()
        window.location.assign('/login')
        break
      case 502:
    }
    return Promise.reject(err.response?.data)
  },
)
