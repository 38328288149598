import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  errorItemData,
  errorListData,
  receiveItemData,
  receiveListData,
  requestItemData,
  requestListData,
} from '@/common/utils/state.utils'

import { ISupportTicket, ITicket } from '@/features/support/interfaces/support.interface'
import { supportService } from '@/features/support/state/support.service'
import { ISupportState, SupportState } from '@/features/support/state/support.state.interface'

const initialState = new SupportState()

const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    updateTicket(state, action: PayloadAction<ITicket>) {
      state.ticketDetails = { ...state.ticketDetails, data: action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(supportService.fetchTickets.pending, (state) => {
        return requestListData<ISupportState, ISupportTicket[]>({ ...state }, ['tickets'])
      })
      .addCase(supportService.fetchTickets.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<ISupportState, ISupportTicket[]>({ ...state }, ['tickets'], items, total_items)
      })
      .addCase(supportService.fetchTickets.rejected, (state, action) => {
        return errorListData<ISupportState, ISupportTicket[]>({ ...state }, ['tickets'], String(action.error.message))
      })
      .addCase(supportService.fetchTicketDetails.pending, (state) => {
        return requestListData<ISupportState, ITicket>({ ...state }, ['ticketDetails'])
      })
      .addCase(supportService.fetchTicketDetails.fulfilled, (state, action) => {
        return receiveListData<ISupportState, ITicket>({ ...state }, ['ticketDetails'], action.payload)
      })
      .addCase(supportService.fetchTicketDetails.rejected, (state, action) => {
        return errorListData<ISupportState, ITicket>({ ...state }, ['ticketDetails'], String(action.error.message))
      })
      .addCase(supportService.addTicketMessage.pending, (state) => {
        return requestItemData<ISupportState, ITicket>({ ...state }, ['addTicketMessageStatus'])
      })
      .addCase(supportService.addTicketMessage.fulfilled, (state, action) => {
        return receiveItemData<ISupportState, ITicket>({ ...state }, ['addTicketMessageStatus'], action.payload)
      })
      .addCase(supportService.addTicketMessage.rejected, (state, action) => {
        return errorItemData<ISupportState, ITicket>(
          { ...state },
          ['addTicketMessageStatus'],
          String(action.error.message),
        )
      })
      .addCase(supportService.updateTicketStatus.pending, (state) => {
        return requestListData<ISupportState, ITicket>({ ...state }, ['updateTicketStatus'])
      })
      .addCase(supportService.updateTicketStatus.fulfilled, (state, action) => {
        return receiveListData<ISupportState, ITicket>({ ...state }, ['updateTicketStatus'], action.payload)
      })
      .addCase(supportService.updateTicketStatus.rejected, (state, action) => {
        return errorListData<ISupportState, ITicket>({ ...state }, ['updateTicketStatus'], String(action.error.message))
      })
  },
})

export const { updateTicket } = supportSlice.actions

export default supportSlice.reducer
