import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'

import { IAuth, ILogin, IResetPassword } from 'src/features/auth/interfaces/auth.interface'

export interface IAuthState {
  login: IStoreApiItem<ILogin>
  forgotPassword: IStoreApiItem<IAuth>
  resetPassword: IStoreApiItem<IResetPassword>
}

export class AuthState implements IAuthState {
  isCaptchaValid: boolean = false
  login: IStoreApiItem<ILogin> = new StoreApiItem()
  forgotPassword: IStoreApiItem<IAuth> = new StoreApiItem()
  resetPassword: IStoreApiItem<IResetPassword> = new StoreApiItem()
}
