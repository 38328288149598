import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import { ISitePrice, ISitePrices } from '@/features/sites/interfaces/prices.interfaces'

export interface IPriceState {
  prices: IStoreApiList<ISitePrices>
  price: IStoreApiList<ISitePrice>
  updatePrices: IStoreApiList<ISitePrices>
}

export class PriceState implements IPriceState {
  prices: IStoreApiList<ISitePrices> = new StoreApiList()
  price: IStoreApiList<ISitePrice> = new StoreApiList()
  updatePrices: IStoreApiList<ISitePrices> = new StoreApiList()
}
