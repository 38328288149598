import { createSlice } from '@reduxjs/toolkit'

import { StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import {
  errorItemData,
  errorListData,
  errorPostPutItemData,
  receiveItemData,
  receiveListData,
  receivePostPutItemData,
  requestItemData,
  requestListData,
  requestPostPutItemData,
} from '@/common/utils/state.utils'

import {
  IGeneratePromotion,
  IPromotion,
  IPromotionConfigData,
} from '@/features/promotions/interfaces/promotions.interfaces'
import { promotionsService } from '@/features/promotions/state/promotions.service'
import { PromotionsState } from '@/features/promotions/state/promotions.state.interface'

const initialState = new PromotionsState()

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    resetPromotion(state) {
      return { ...state, promotion: new StoreApiItem<IPromotion>() }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(promotionsService.fetchPromotions.pending, (state) => {
        return requestListData<PromotionsState, IPromotion[]>({ ...state }, ['promotions'])
      })
      .addCase(promotionsService.fetchPromotions.fulfilled, (state, action) => {
        return receiveListData<PromotionsState, IPromotion[]>(
          { ...state },
          ['promotions'],
          action.payload.deals,
          action.payload.total_items,
        )
      })
      .addCase(promotionsService.fetchPromotions.rejected, (state, action) => {
        return errorListData<PromotionsState, IPromotion[]>({ ...state }, ['promotions'], String(action.error.message))
      })
      .addCase(promotionsService.fetchPromotionsConfigData.pending, (state) => {
        return requestItemData<PromotionsState, IPromotionConfigData>({ ...state }, ['configData'])
      })
      .addCase(promotionsService.fetchPromotionsConfigData.fulfilled, (state, action) => {
        return receiveItemData<PromotionsState, IPromotionConfigData>({ ...state }, ['configData'], action.payload)
      })
      .addCase(promotionsService.fetchPromotionsConfigData.rejected, (state, action) => {
        return errorItemData<PromotionsState, IPromotionConfigData>(
          { ...state },
          ['configData'],
          String(action.error.message),
        )
      })
      .addCase(promotionsService.fetchPromotionById.pending, (state) => {
        return requestItemData<PromotionsState, IPromotion>({ ...state }, ['promotion'])
      })
      .addCase(promotionsService.fetchPromotionById.fulfilled, (state, action) => {
        return receiveItemData<PromotionsState, IPromotion>({ ...state }, ['promotion'], action.payload)
      })
      .addCase(promotionsService.fetchPromotionById.rejected, (state, action) => {
        return errorItemData<PromotionsState, IPromotion>({ ...state }, ['promotion'], String(action.error.message))
      })
      .addCase(promotionsService.generatePromotion.pending, (state) => {
        return requestPostPutItemData<PromotionsState, IGeneratePromotion>({ ...state }, ['generatedPromotion'])
      })
      .addCase(promotionsService.generatePromotion.fulfilled, (state, action) => {
        return receivePostPutItemData<PromotionsState, IGeneratePromotion>(
          { ...state },
          ['generatedPromotion'],
          action.payload,
        )
      })
      .addCase(promotionsService.generatePromotion.rejected, (state, action) => {
        return errorPostPutItemData<PromotionsState, IGeneratePromotion>(
          { ...state },
          ['generatedPromotion'],
          String(action.error.message),
        )
      })
  },
})

export const { resetPromotion } = promotionsSlice.actions
export default promotionsSlice.reducer
