import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import { IOption, PriceIntervalsResponse } from '@/features/options/interfaces/option.interface'

export interface IOptionState {
  options: IStoreApiList<IOption[]>
  option: IStoreApiItem<IOption>
  optionsPriceIntervals: IStoreApiItem<PriceIntervalsResponse>
  optionPriceIntervalsForCategory: IStoreApiItem<PriceIntervalsResponse>
}

export class OptionState implements IOptionState {
  options: IStoreApiList<IOption[]> = new StoreApiList()
  option: IStoreApiItem<IOption> = new StoreApiItem()
  optionsPriceIntervals: IStoreApiItem<PriceIntervalsResponse> = new StoreApiItem()
  optionPriceIntervalsForCategory: IStoreApiItem<PriceIntervalsResponse> = new StoreApiItem()
}
