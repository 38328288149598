import { createSlice } from '@reduxjs/toolkit'

import {
  errorDeleteItem,
  errorListData,
  errorPostPutItemData,
  receiveDeleteItem,
  receiveListData,
  receivePostPutItemData,
  requestDeleteItem,
  requestListData,
  requestPostPutItemData,
} from '@/common/utils/state.utils'

import { IHardware } from '@/features/hardware/interfaces/hardware.interface'

import { hardwaresService } from './hardwares.service'
import { IHardwareState, HardwareState } from './hardwares.state.interface'

const initialState = new HardwareState()

const hardwareSlice = createSlice({
  name: 'hardwares',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(hardwaresService.fetchHardwares.pending, (state) => {
        return requestListData<IHardwareState, IHardware[]>({ ...state }, ['hardwares'])
      })
      .addCase(hardwaresService.fetchHardwares.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<IHardwareState, IHardware[]>({ ...state }, ['hardwares'], items, total_items)
      })
      .addCase(hardwaresService.fetchHardwares.rejected, (state, action) => {
        return errorListData<IHardwareState, IHardware[]>({ ...state }, ['hardwares'], String(action.error.message))
      })
      .addCase(hardwaresService.fetchHardware.pending, (state) => {
        return requestListData<IHardwareState, IHardware>({ ...state }, ['hardware'])
      })
      .addCase(hardwaresService.fetchHardware.fulfilled, (state, action) => {
        return receiveListData<IHardwareState, IHardware>({ ...state }, ['hardware'], action.payload)
      })
      .addCase(hardwaresService.fetchHardware.rejected, (state, action) => {
        return errorListData<IHardwareState, IHardware>({ ...state }, ['hardware'], String(action.error.message))
      })

      .addCase(hardwaresService.addHardware.pending, (state) => {
        return requestDeleteItem<IHardwareState, IHardware>({ ...state }, ['addedHardware'])
      })
      .addCase(hardwaresService.addHardware.fulfilled, (state) => {
        return receiveDeleteItem<IHardwareState, IHardware>({ ...state }, ['addedHardware'])
      })
      .addCase(hardwaresService.addHardware.rejected, (state, action) => {
        return errorDeleteItem<IHardwareState, IHardware>({ ...state }, ['addedHardware'], String(action.error.message))
      })

      .addCase(hardwaresService.deleteHardware.pending, (state) => {
        return requestDeleteItem<IHardwareState, IHardware>({ ...state }, ['deletedHardware'])
      })
      .addCase(hardwaresService.deleteHardware.fulfilled, (state) => {
        return receiveDeleteItem<IHardwareState, IHardware>({ ...state }, ['deletedHardware'])
      })
      .addCase(hardwaresService.deleteHardware.rejected, (state, action) => {
        return errorDeleteItem<IHardwareState, IHardware>(
          { ...state },
          ['deletedHardware'],
          String(action.error.message),
        )
      })

      .addCase(hardwaresService.updateHardware.pending, (state) => {
        return requestPostPutItemData<IHardwareState, IHardware>({ ...state }, ['updatedHardware'])
      })
      .addCase(hardwaresService.updateHardware.fulfilled, (state, action) => {
        return receivePostPutItemData<IHardwareState, IHardware>({ ...state }, ['updatedHardware'], action.payload)
      })
      .addCase(hardwaresService.updateHardware.rejected, (state, action) => {
        return errorPostPutItemData<IHardwareState, IHardware>(
          { ...state },
          ['updatedHardware'],
          String(action.error.message),
        )
      })
  },
})

export default hardwareSlice.reducer
