import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import { IFaq } from 'src/features/faq/interfaces/faq.interface'

export interface IFaqState {
  faqs: IStoreApiList<IFaq[]>
  faq: IStoreApiItem<IFaq>
  faqUpdate: IStoreApiItem<IFaq>
  faqDelete: IStoreApiItem<IFaq>
  faqCreate: IStoreApiItem<IFaq>
}

export class FaqState implements IFaqState {
  faqs: IStoreApiList<IFaq[]> = new StoreApiList()
  faq: IStoreApiItem<IFaq> = new StoreApiItem()
  faqUpdate: IStoreApiItem<IFaq> = new StoreApiItem()
  faqDelete: IStoreApiItem<IFaq> = new StoreApiItem()
  faqCreate: IStoreApiItem<IFaq> = new StoreApiItem()
}
