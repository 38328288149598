import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import { IAmbientMusic, IAmbientMusicPostPut, IMarketingImages } from 'src/features/media/interfaces/media.interface'

export interface IMediaState {
  marketingImages: IStoreApiItem<IMarketingImages>
  marketingAddImage: IStoreApiItem<null>
  marketingDeleteImage: IStoreApiItem<null>
  marketingMusic: IStoreApiList<IAmbientMusic[]>
  marketingMusicAdd: IStoreApiList<IAmbientMusicPostPut>
  marketingMusicUpdate: IStoreApiList<IAmbientMusicPostPut>
  marketingMusicDelete: IStoreApiList<null>
}

export class MediaState implements IMediaState {
  marketingImages: IStoreApiItem<IMarketingImages> = new StoreApiItem()
  marketingAddImage: IStoreApiItem<null> = new StoreApiItem()
  marketingDeleteImage: IStoreApiItem<null> = new StoreApiItem()
  marketingMusic: IStoreApiList<IAmbientMusic[]> = new StoreApiList()
  marketingMusicAdd: IStoreApiList<IAmbientMusicPostPut> = new StoreApiItem()
  marketingMusicUpdate: IStoreApiList<IAmbientMusicPostPut> = new StoreApiItem()
  marketingMusicDelete: IStoreApiList<null> = new StoreApiItem()
}
