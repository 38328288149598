import { createAsyncThunk } from '@reduxjs/toolkit'
import type { TablePaginationConfig } from 'antd/es/table'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'
import { buildPaginationQuery } from '@/common/utils/table.utils'

const baseUrl = `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/levels`

const fetchLevels = createAsyncThunk('levels/fetchLevels', async (tableParams: TablePaginationConfig) => {
  const response = await api(HttpMethods.GET, buildPaginationQuery(baseUrl, tableParams), {})
  return response.data
})

const updateLevel = createAsyncThunk('levels/updateLevel', async (data: any) => {
  const response = await api(HttpMethods.PUT, `${baseUrl}/${data.data.id}`, data.data)
  return response.data
})

const deleteLevel = createAsyncThunk('levels/deleteLevel', async (id: number) => {
  const response = await api(HttpMethods.DELETE, `${baseUrl}/${id}`, {})
  return response.data
})

const addLevel = createAsyncThunk('levels/addLevel', async (data: any) => {
  const response = await api(HttpMethods.POST, baseUrl, data.data)
  return response.data
})

export const levelsService = {
  fetchLevels,
  updateLevel,
  deleteLevel,
  addLevel,
}
