import React, { FC, Suspense } from 'react'

import PageSpinner from '@/common/components/page-spinner/page-spinner'

import { IFallbackComponentProps } from './fallback-component.types'

const FallbackComponent: FC<IFallbackComponentProps> = ({ ...props }) => {
  return <Suspense fallback={<PageSpinner />}>{props.children}</Suspense>
}

export default FallbackComponent
