import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ICodeName } from '@/common/interfaces/code-name.interface'
import {
  errorListData,
  receiveListData,
  requestListData,
  requestPostPutItemData,
  receivePostPutItemData,
  errorPostPutItemData,
  requestDeleteItem,
  receiveDeleteItem,
  errorDeleteItem,
} from '@/common/utils/state.utils'

import {
  IRoom,
  IRoomHardwareDetails,
  IRoomHardwareItem,
  IRoomsLeases,
} from '@/features/sites/interfaces/site.interface'
import { siteRoomsService } from '@/features/sites/state/rooms/siteRooms.service'
import { ISiteRoomState, SiteRoomState } from '@/features/sites/state/rooms/siteRooms.state.interface'

const initialState = new SiteRoomState()

const siteRoomsSlice = createSlice({
  name: 'siteRooms',
  initialState,
  reducers: {
    UPDATE_ROOM_HARDWARE_COMBINE(state, action: PayloadAction<IRoomHardwareItem>) {
      if (state.roomHardwareCombine.some((hardware: IRoomHardwareItem) => hardware.id === action.payload.id))
        state.roomHardwareCombine = state.roomHardwareCombine.filter(
          (hardware: IRoomHardwareItem) => hardware.id !== action.payload.id,
        )
      else state.roomHardwareCombine.push(action.payload)
    },

    SET_ROOM_HARDWARE_COMBINE(state, action: PayloadAction<IRoomHardwareItem[]>) {
      state.roomHardwareCombine = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(siteRoomsService.fetchSiteRooms.pending, (state) => {
        return requestListData<ISiteRoomState, IRoom[]>({ ...state }, ['rooms'])
      })
      .addCase(siteRoomsService.fetchSiteRooms.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<ISiteRoomState, IRoom[]>({ ...state }, ['rooms'], items, total_items)
      })
      .addCase(siteRoomsService.fetchSiteRooms.rejected, (state, action) => {
        return errorListData<ISiteRoomState, IRoom[]>({ ...state }, ['rooms'], String(action.error.message))
      })

      .addCase(siteRoomsService.fetchAllHardwares.pending, (state) => {
        return requestListData<ISiteRoomState, IRoomHardwareItem[]>({ ...state }, ['allHardwares'])
      })
      .addCase(siteRoomsService.fetchAllHardwares.fulfilled, (state, action) => {
        const { items } = action.payload
        return receiveListData<ISiteRoomState, IRoomHardwareItem[]>({ ...state }, ['allHardwares'], items)
      })
      .addCase(siteRoomsService.fetchAllHardwares.rejected, (state, action) => {
        return errorListData<ISiteRoomState, IRoomHardwareItem[]>(
          { ...state },
          ['allHardwares'],
          String(action.error.message),
        )
      })

      .addCase(siteRoomsService.fetchSiteRoomHardware.pending, (state) => {
        return requestListData<ISiteRoomState, IRoomHardwareDetails>({ ...state }, ['roomHardwareDetails'])
      })
      .addCase(siteRoomsService.fetchSiteRoomHardware.fulfilled, (state, action) => {
        return receiveListData<ISiteRoomState, IRoomHardwareDetails>(
          { ...state },
          ['roomHardwareDetails'],
          action.payload,
        )
      })
      .addCase(siteRoomsService.fetchSiteRoomHardware.rejected, (state, action) => {
        return errorListData<ISiteRoomState, IRoomHardwareDetails>(
          { ...state },
          ['roomHardwareDetails'],
          String(action.error.message),
        )
      })

      .addCase(siteRoomsService.fetchSiteRoom.pending, (state) => {
        return requestListData<ISiteRoomState, IRoom>({ ...state }, ['room'])
      })
      .addCase(siteRoomsService.fetchSiteRoom.fulfilled, (state, action) => {
        return receiveListData<ISiteRoomState, IRoom>({ ...state }, ['room'], action.payload)
      })
      .addCase(siteRoomsService.fetchSiteRoom.rejected, (state, action) => {
        return errorListData<ISiteRoomState, IRoom>({ ...state }, ['room'], String(action.error.message))
      })

      .addCase(siteRoomsService.addSiteRoom.pending, (state) => {
        return requestPostPutItemData<ISiteRoomState, IRoom>({ ...state }, ['room'])
      })
      .addCase(siteRoomsService.addSiteRoom.fulfilled, (state, action) => {
        return receivePostPutItemData<ISiteRoomState, IRoom>({ ...state }, ['room'], action.payload)
      })
      .addCase(siteRoomsService.addSiteRoom.rejected, (state, action) => {
        return errorPostPutItemData<ISiteRoomState, IRoom>({ ...state }, ['room'], String(action.error.message))
      })

      .addCase(siteRoomsService.updateRoom.pending, (state) => {
        return requestPostPutItemData<ISiteRoomState, IRoom>({ ...state }, ['room'])
      })
      .addCase(siteRoomsService.updateRoom.fulfilled, (state, action) => {
        return receivePostPutItemData<ISiteRoomState, IRoom>({ ...state }, ['room'], action.payload)
      })
      .addCase(siteRoomsService.updateRoom.rejected, (state, action) => {
        return errorPostPutItemData<ISiteRoomState, IRoom>({ ...state }, ['room'], String(action.error.message))
      })

      .addCase(siteRoomsService.fetchSiteRoomTypes.pending, (state) => {
        return requestListData<ISiteRoomState, ICodeName[]>({ ...state }, ['roomTypes'])
      })
      .addCase(siteRoomsService.fetchSiteRoomTypes.fulfilled, (state, action) => {
        return receiveListData<ISiteRoomState, ICodeName[]>({ ...state }, ['roomTypes'], action.payload)
      })
      .addCase(siteRoomsService.fetchSiteRoomTypes.rejected, (state, action) => {
        return errorListData<ISiteRoomState, ICodeName[]>({ ...state }, ['roomTypes'], String(action.error.message))
      })

      .addCase(siteRoomsService.deleteRoomSite.pending, (state) => {
        return requestDeleteItem<ISiteRoomState, IRoom>({ ...state }, ['room'])
      })
      .addCase(siteRoomsService.deleteRoomSite.fulfilled, (state) => {
        return receiveDeleteItem<ISiteRoomState, IRoom>({ ...state }, ['room'])
      })
      .addCase(siteRoomsService.deleteRoomSite.rejected, (state, action) => {
        return errorDeleteItem<ISiteRoomState, IRoom>({ ...state }, ['room'], String(action.error.message))
      })

      .addCase(siteRoomsService.fetchSiteRoomsLeases.pending, (state) => {
        return requestListData<ISiteRoomState, IRoomsLeases[]>({ ...state }, ['roomsLeases'])
      })
      .addCase(siteRoomsService.fetchSiteRoomsLeases.fulfilled, (state, action) => {
        return receiveListData<ISiteRoomState, IRoomsLeases[]>({ ...state }, ['roomsLeases'], action.payload)
      })
      .addCase(siteRoomsService.fetchSiteRoomsLeases.rejected, (state, action) => {
        return errorListData<ISiteRoomState, IRoomsLeases[]>(
          { ...state },
          ['roomsLeases'],
          String(action.error.message),
        )
      })

      .addCase(siteRoomsService.fetchRoomsWithOptions.pending, (state) => {
        return requestListData<ISiteRoomState, IRoomsLeases[]>({ ...state }, ['roomsWithOptions'])
      })
      .addCase(siteRoomsService.fetchRoomsWithOptions.fulfilled, (state, action) => {
        return receiveListData<ISiteRoomState, IRoomsLeases[]>({ ...state }, ['roomsWithOptions'], action.payload)
      })
      .addCase(siteRoomsService.fetchRoomsWithOptions.rejected, (state, action) => {
        return errorListData<ISiteRoomState, IRoomsLeases[]>(
          { ...state },
          ['roomsWithOptions'],
          String(action.error.message),
        )
      })
  },
})

export const { UPDATE_ROOM_HARDWARE_COMBINE, SET_ROOM_HARDWARE_COMBINE } = siteRoomsSlice.actions

export default siteRoomsSlice.reducer
