import { createAsyncThunk } from '@reduxjs/toolkit'
import type { TablePaginationConfig } from 'antd/es/table'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'
import { IType } from '@/common/interfaces/type.interfaces'
import { buildPaginationQuery } from '@/common/utils/table.utils'

const fetchTypes = createAsyncThunk(
  'root/fetchTypes',
  async (payload: { classCode: number; tableParams: TablePaginationConfig }) => {
    const response = await api(
      HttpMethods.GET,
      buildPaginationQuery(
        `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/types/${payload.classCode}`,
        payload.tableParams,
      ),
    )
    return response.data
  },
)

const deleteType = createAsyncThunk('root/deleteType', async (payload: { classCode: number; itemCode: number }) => {
  const response = await api(
    HttpMethods.DELETE,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/types/${payload.classCode}/${payload.itemCode}`,
  )
  return response.data
})

const updateType = createAsyncThunk(
  'root/updateType',
  async (payload: { classCode: number; itemCode: number; data: Partial<IType> }) => {
    const response = await api(
      HttpMethods.PUT,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/types/${payload.classCode}/${payload.itemCode}`,
      payload.data,
    )
    return response.data
  },
)

const addType = createAsyncThunk('root/addType', async (payload: { classCode: number; data: Partial<IType> }) => {
  const response = await api(
    HttpMethods.POST,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/types/${payload.classCode}`,
    payload.data,
  )
  return response.data
})

const fetchCountries = createAsyncThunk('root/fetchCountries', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/countries`)
  return response.data
})

export const typesService = {
  fetchTypes,
  deleteType,
  updateType,
  addType,
  fetchCountries,
}
