import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import { IRentsTax, ISalesTax } from 'src/features/sites/interfaces/taxes.interface'

export interface ITaxesState {
  salesTaxes: IStoreApiList<ISalesTax[]>
  rentTaxes: IStoreApiList<IRentsTax[]>
  fees: IStoreApiList<ISalesTax[]>
  deposit: IStoreApiList<ISalesTax[]>
}

export class TaxesState implements ITaxesState {
  salesTaxes: IStoreApiList<ISalesTax[]> = new StoreApiList()
  rentTaxes: IStoreApiList<IRentsTax[]> = new StoreApiList()
  fees: IStoreApiList<ISalesTax[]> = new StoreApiList()
  deposit: IStoreApiList<ISalesTax[]> = new StoreApiList()
}
