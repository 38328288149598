import { INavigationList } from '@/models/INavigation'

export enum sidebarMenuLabels {
  ACCESS = 'Access',
  COMPONENTS = '/components',
  FAQ = 'FAQ',
  HARDWARE = 'Hardware',
  HOME = 'Home',
  LEASES = 'Bookings',
  LEVELS = 'Levels',
  LOGIN = 'Login',
  MEDIA = 'Media',
  OPTIONS = 'Options',
  ROOMS = 'Rooms ',
  PROMOTIONS = 'Promotions',
  SITES = 'Sites',
  SITES_INFO = 'Sites Info',
  STAFF = 'Staff',
  SUPPORT = 'Support',
  TEMPLATES = 'Templates',
  TERMS = 'Terms',
  TYPES = 'Types',
  USERS = 'Users',
  TRANSLATE = 'Translate',
}

export const sidebarMenus: INavigationList[] = [
  {
    route: '/home',
    title: sidebarMenuLabels.HOME,
  },
  {
    route: '/site',
    title: sidebarMenuLabels.SITES,
  },

  {
    route: 'site-rooms',
    title: sidebarMenuLabels.ROOMS,
  },
  {
    route: '/bookings',
    title: sidebarMenuLabels.LEASES,
  },
  {
    route: '/promotions',
    title: sidebarMenuLabels.PROMOTIONS,
  },
  {
    route: '',
    title: sidebarMenuLabels.TEMPLATES,
  },
  {
    route: '/users',
    title: sidebarMenuLabels.USERS,
  },
  {
    route: '/support',
    title: sidebarMenuLabels.SUPPORT,
  },

  // {
  //   route: '/access',
  //   title: sidebarMenuLabels.ACCESS,
  // },

  {
    route: '/media',
    title: sidebarMenuLabels.MEDIA,
  },
  {
    route: '/translate',
    title: sidebarMenuLabels.TRANSLATE,
  },
]

export const centralSiteSidebarMenus: INavigationList[] = [
  {
    route: '/sites',
    title: sidebarMenuLabels.SITES,
  },
  {
    route: '',
    title: sidebarMenuLabels.TYPES,
  },
  {
    route: '/users',
    title: sidebarMenuLabels.USERS,
  },
  {
    route: '/levels',
    title: sidebarMenuLabels.LEVELS,
  },
  {
    route: '/faqs',
    title: sidebarMenuLabels.FAQ,
  },
  {
    route: '/terms',
    title: sidebarMenuLabels.TERMS,
  },
]

export const typesTemplatesMenus: INavigationList[] = [
  {
    route: '/hardware',
    title: sidebarMenuLabels.HARDWARE,
    additionalRouteName: 'hardware',
  },
  {
    route: '/rooms',
    title: sidebarMenuLabels.ROOMS,
    additionalRouteName: 'rooms',
  },
  {
    route: '/options',
    title: sidebarMenuLabels.OPTIONS,
    additionalRouteName: 'options',
  },
]

export const siteMenus: INavigationList[] = [
  {
    route: '/rooms',
    title: sidebarMenuLabels.ROOMS,
    additionalRouteName: 'rooms',
  },
]
