import { createAsyncThunk } from '@reduxjs/toolkit'
import { TablePaginationConfig } from 'antd'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'
import { buildPaginationQuery } from '@/common/utils/table.utils'

const baseUrl = `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/translate`

const fetchTranslations = createAsyncThunk(
  'translations/fetchTranslations',
  async (tableParams: TablePaginationConfig) => {
    const response = await api(HttpMethods.GET, buildPaginationQuery(baseUrl, tableParams), {})
    return response.data
  },
)

const fetchTranslation = createAsyncThunk(
  'translations/fetchTranslation',
  async ({ key, language }: { key: string; language: string }) => {
    const response = await api(HttpMethods.GET, `${baseUrl}/${key}/${language}`, {})
    return response.data
  },
)

const addTranslation = createAsyncThunk('translations/addTranslation', async (data: any) => {
  const response = await api(HttpMethods.POST, baseUrl, data)
  return response.data
})

const addTranslationVariables = createAsyncThunk(
  'translations/addTranslationVariables',
  async ({ key, variables }: { key: string; variables: string[] }) => {
    const response = await api(HttpMethods.POST, `${baseUrl}/${key}/variables`, { variables })
    return response.data
  },
)

const updateTranslation = createAsyncThunk(
  'translations/updateTranslation',
  async (data: { key: string; language: string; text: string; type: string; label: string }) => {
    const response = await api(HttpMethods.PUT, `${baseUrl}/${data.key}`, data)
    return response.data
  },
)

export const translationsService = {
  fetchTranslations,
  fetchTranslation,
  addTranslation,
  addTranslationVariables,
  updateTranslation,
}
