import { SortsType, TableParamsType } from 'src/common/interfaces/fancy-table.interfaces'

export const formatSortersAsParams = (sorters: SortsType) => {
  const sortArray = Object.keys(sorters)
    .map((key) => ({
      key,
      ...sorters[key],
    }))
    .sort((a, b) => (a.multiple || 0) - (b.multiple || 0))

  const performedArr = sortArray.map((item) => {
    const sign = item.order === 'descend' ? '-' : ''
    return sign + item.key
  })
  return performedArr.join(',')
}

export const buildPaginationQuery = (baseURL: string, params: TableParamsType): string => {
  let url = `${baseURL}?per_page=${params.pageSize}&page=${params.current}`

  if (params?.sorters && Object.keys(params.sorters).length > 0) {
    const sortParam = formatSortersAsParams(params.sorters)
    url += `&sort=${sortParam}`
  }
  return url
}
