import { ICode } from '@/common/interfaces/code-name.interface'
import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import {
  ILease,
  ILeaseAnnounce,
  ILeaseCode,
  ILeaseDamageItem,
  ILeaseDamages,
  ILeaseDeposit,
  ILeaseDocument,
  ILeaseDuring,
  ILeaseFromInterval,
  ILeaseInvoice,
  ILeaseMedia,
  ILeasePatient,
  ILeasesTypesSite,
  LeaseDayDetails,
} from '@/features/leases/interfaces/leases.interface'

export interface ILeaseState {
  leases: IStoreApiList<ILease[]>
  leasesSimpleReport: IStoreApiList<ILease[]>
  leasesDetailedReport: IStoreApiList<ILease[]>
  lease: IStoreApiItem<ILease>
  leaseDay: IStoreApiList<LeaseDayDetails>
  leaseCodes: IStoreApiList<ILeaseCode[]>
  leaseMedia: IStoreApiList<ILeaseMedia>
  leaseDeposit: IStoreApiItem<ILeaseDeposit>
  leaseDamages: IStoreApiList<ILeaseDamages[]>
  leaseDamagesList: IStoreApiList<ILeaseDamageItem[]>
  leaseDocuments: IStoreApiList<ILeaseDocument[]>
  leaseInvoices: IStoreApiList<ILeaseInvoice[]>
  leasePatients: IStoreApiList<ILeasePatient[]>
  doctorsLeases: IStoreApiList<ILease[]>
  leaseDuring: IStoreApiList<ILeaseDuring[]>
  leasesInterval: IStoreApiItem<ILeaseFromInterval>
  leaseTypesSite: IStoreApiItem<ILeasesTypesSite>
  leasesAnnounce: IStoreApiList<ILeaseAnnounce[]>
  bookingTypes: IStoreApiList<ICode[]>
}

export class LeaseState implements ILeaseState {
  leases: IStoreApiList<ILease[]> = new StoreApiList()
  leasesSimpleReport: IStoreApiList<ILease[]> = new StoreApiList()
  leasesDetailedReport: IStoreApiList<ILease[]> = new StoreApiList()
  lease: IStoreApiItem<ILease> = new StoreApiItem()
  leaseDay: IStoreApiList<LeaseDayDetails> = new StoreApiItem()
  leaseCodes: IStoreApiList<ILeaseCode[]> = new StoreApiList()
  leaseMedia: IStoreApiList<ILeaseMedia> = new StoreApiList()
  leaseDeposit: IStoreApiItem<ILeaseDeposit> = new StoreApiItem()
  leaseDamages: IStoreApiList<ILeaseDamages[]> = new StoreApiList()
  leaseDamagesList: IStoreApiList<ILeaseDamageItem[]> = new StoreApiList()
  leaseDocuments: IStoreApiList<ILeaseDocument[]> = new StoreApiList()
  leaseInvoices: IStoreApiList<ILeaseInvoice[]> = new StoreApiList()
  leasePatients: IStoreApiList<ILeasePatient[]> = new StoreApiList()
  doctorsLeases: IStoreApiList<ILease[]> = new StoreApiList()
  leaseDuring: IStoreApiList<ILeaseDuring[]> = new StoreApiList()
  leasesInterval: IStoreApiItem<ILeaseFromInterval> = new StoreApiItem()
  leaseTypesSite: IStoreApiItem<ILeasesTypesSite> = new StoreApiItem()
  leasesAnnounce: IStoreApiList<ILeaseAnnounce[]> = new StoreApiList()
  bookingTypes: IStoreApiList<ICode[]> = new StoreApiList()
}
