/* eslint-disable */
import { IStoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList } from '@/common/interfaces/store-api-list.interface'

const getStateWithUpdatedPart: <StateType, UpdatedType>(
  lastState: StateType,
  pathToUpdate: string[],
  updatedPathState: UpdatedType,
) => StateType = <StateType, UpdatedType>(
  lastState: StateType,
  pathToUpdate: string[],
  updatedPathState: UpdatedType,
) => {
  switch (pathToUpdate.length) {
    case 1: {
      return { ...lastState, [pathToUpdate[0]]: { ...(lastState as any)[pathToUpdate[0]], ...updatedPathState } }
    }

    case 2: {
      return {
        ...lastState,
        [pathToUpdate[0]]: {
          ...(lastState as any)[pathToUpdate[0]],
          [pathToUpdate[1]]: {
            ...(lastState as any)[pathToUpdate[0]][pathToUpdate[1]],
            ...updatedPathState,
          },
        },
      }
    }

    case 3: {
      return {
        ...lastState,
        [pathToUpdate[0]]: {
          ...(lastState as any)[pathToUpdate[0]],
          [pathToUpdate[1]]: {
            ...(lastState as any)[pathToUpdate[0]][pathToUpdate[1]],
            [pathToUpdate[2]]: {
              ...(lastState as any)[pathToUpdate[0]][pathToUpdate[1]][pathToUpdate[2]],
              ...updatedPathState,
            },
          },
        },
      }
    }

    default:
      throw new Error(
        "getStateWithUpdatedPart doesn't supports only 1, 2 or 3 levels deep. Extend the method to get more.",
      )
  }
}

export const requestListData: <StateType, _ListType>(lastState: StateType, pathToUpdate: string[]) => StateType = <
  StateType,
  ListType,
>(
  lastState: StateType,
  pathToUpdate: string[],
) => {
  const updatedPathState: IStoreApiList<ListType> = {
    isLoading: true,
    isSuccess: false,
    errors: null,
    data: null,
  }

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState)
}

export const receiveListData: <StateType, ListType>(
  lastState: StateType,
  pathToUpdate: string[],
  data: ListType,
  total_items?: number,
) => StateType = <StateType, ListType>(
  lastState: StateType,
  pathToUpdate: string[],
  data: ListType,
  total_items?: number | undefined,
) => {
  const updatedPathState: IStoreApiList<ListType> = {
    isLoading: false,
    isSuccess: true,
    errors: null,
    data,
    total_items,
  }

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState)
}

export const errorListData: <StateType, _ListType>(
  lastState: StateType,
  pathToUpdate: string[],
  errors: string,
) => StateType = <StateType, ListType>(lastState: StateType, pathToUpdate: string[], errors: string) => {
  const updatedPathState: IStoreApiList<ListType> = {
    ...lastState,
    isLoading: false,
    isSuccess: false,
    errors,
    data: null,
  }

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState)
}

export const requestItemData: <StateType, _ItemType>(lastState: StateType, pathToUpdate: string[]) => StateType = <
  StateType,
  ItemType,
>(
  lastState: StateType,
  pathToUpdate: string[],
) => {
  const updatedPathState: Partial<IStoreApiItem<ItemType>> = {
    isLoading: true,
    isSuccess: false,
    errors: null,
    data: null,
  }

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState)
}

export const receiveItemData: <StateType, ItemType>(
  lastState: StateType,
  pathToUpdate: string[],
  data: ItemType,
) => StateType = <StateType, ItemType>(lastState: StateType, pathToUpdate: string[], data: ItemType) => {
  const updatedPathState: Partial<IStoreApiItem<ItemType>> = { isLoading: false, isSuccess: true, errors: null, data }

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState)
}

export const errorItemData: <StateType, _ItemType>(
  lastState: StateType,
  pathToUpdate: string[],
  errors: string,
) => StateType = <StateType, ItemType>(lastState: StateType, pathToUpdate: string[], errors: string) => {
  const updatedPathState: Partial<IStoreApiItem<ItemType>> = {
    ...lastState,
    isLoading: false,
    isSuccess: false,
    errors,
    data: null,
  }

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState)
}

export const requestPostPutItemData: <StateType, ItemType>(
  lastState: StateType,
  pathToUpdate: string[],
  data?: ItemType,
) => StateType = <StateType, ItemType>(lastState: StateType, pathToUpdate: string[], data: ItemType) => {
  const updatedPathState: Partial<IStoreApiItem<ItemType>> = data
    ? { isLoading: true, errors: null, data, isSuccess: false }
    : { isLoading: true, errors: null, isSuccess: false }

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState)
}

export const receivePostPutItemData: <StateType, ItemType>(
  lastState: StateType,
  pathToUpdate: string[],
  data: ItemType,
) => StateType = <StateType, ItemType>(lastState: StateType, pathToUpdate: string[], data: ItemType) => {
  const updatedPathState: Partial<IStoreApiItem<ItemType>> = data
    ? { isLoading: false, errors: null, data, isSuccess: true }
    : { isLoading: false, errors: null, isSuccess: true }

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState)
}

export const errorPostPutItemData: <StateType, _ItemType>(
  lastState: StateType,
  pathToUpdate: string[],
  errors: string,
) => StateType = <StateType, ItemType>(lastState: StateType, pathToUpdate: string[], errors: string) => {
  const updatedPathState: IStoreApiItem<ItemType> = {
    ...lastState,
    isLoading: false,
    errors,
    data: null,
    isSuccess: false,
  }

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState)
}

export const requestDeleteItem: <StateType, _ItemType>(lastState: StateType, pathToUpdate: string[]) => StateType = <
  StateType,
  ItemType,
>(
  lastState: StateType,
  pathToUpdate: string[],
) => {
  const updatedPathState: Partial<IStoreApiItem<ItemType>> = {
    ...lastState,
    isLoading: true,
    isSuccess: false,
  }

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState)
}

export const receiveDeleteItem: <StateType, _ItemType>(lastState: StateType, pathToUpdate: string[]) => StateType = <
  StateType,
  ItemType,
>(
  lastState: StateType,
  pathToUpdate: string[],
) => {
  const updatedPathState: Partial<IStoreApiItem<ItemType>> = {
    ...lastState,
    isLoading: false,
    data: null,
    isSuccess: true,
  }

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState)
}

export const errorDeleteItem: <StateType, _ItemType>(
  lastState: StateType,
  pathToUpdate: string[],
  error: string,
) => StateType = <StateType, ItemType>(lastState: StateType, pathToUpdate: string[], errors: string) => {
  const updatedPathState: Partial<IStoreApiItem<ItemType>> = {
    ...lastState,
    isLoading: false,
    errors,
    isSuccess: false,
  }

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState)
}
