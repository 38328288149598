import { isValidPhoneNumber } from 'react-phone-number-input'

import * as Yup from 'yup'
import { AnyObject, Maybe } from 'yup/lib/types'

import { RegexService } from '@/common/services/regexService'

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    password(): StringSchema<TType, TContext>
    phone(): StringSchema<TType, TContext>
    name(): StringSchema<TType, TContext>
    space(count: number, message: string): StringSchema<TType, TContext>
  }
}

Yup.addMethod(Yup.StringSchema, 'password', function () {
  return this.test(function (value: any) {
    const { path, createError } = this
    switch (Boolean(value)) {
      case !RegexService.includeLowerCase().test(value):
        return createError({ path, message: 'Password must include one lowercase character' })
      case !RegexService.includeUpperCase().test(value):
        return createError({ path, message: 'Password must include one uppercase character' })
      case !RegexService.includeNumber().test(value):
        return createError({ path, message: 'Password must include one number' })
      case !RegexService.includeSpecialChar().test(value):
        return createError({ path, message: 'Password must include special character' })
      case value.length < 8:
        return createError({ path, message: 'Password must include minimum 8 character' })
      default:
        return true
    }
  })
})

Yup.addMethod(Yup.StringSchema, 'phone', function () {
  return this.test(function (value: any) {
    const { path, createError } = this

    try {
      if (value && !isValidPhoneNumber(value)) return createError({ path, message: 'Phone number is not valid!' })
    } catch (error) {
      return false
    }

    return true
  })
})

Yup.addMethod(Yup.StringSchema, 'name', function () {
  return this.test(function (value: any) {
    const { path, createError } = this
    if (!RegexService.name().test(value)) return createError({ path, message: 'Name is not valid' })
    return true
  })
})

Yup.addMethod(Yup.StringSchema, 'space', function (count: number, message: string) {
  return this.test(function (value: any) {
    const { path, createError } = this
    if (value && value.replace(/\s/g, '').length >= count) return true
    return createError({ path, message })
  })
})
