import { createAsyncThunk } from '@reduxjs/toolkit'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'

const fetchSitePrices = createAsyncThunk('prices/fetchSitePrices', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/prices`)
  return response.data
})

const fetchSitePrice = createAsyncThunk('prices/fetchSitePrice', async (price_id: number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/prices/${price_id}`)
  return response.data
})

const updateSitePrices = createAsyncThunk('prices/updateSitePrices', async (data: any) => {
  try {
    const response = await api(
      HttpMethods.PUT,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/prices/${data.scheduleId}`,
      data,
    )
    return response.data
  } catch (error) {
    return error
  }
})

export const sitePricesService = {
  fetchSitePrices,
  updateSitePrices,
  fetchSitePrice,
}
