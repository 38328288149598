import { createAsyncThunk } from '@reduxjs/toolkit'
import type { TablePaginationConfig } from 'antd/es/table'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'
import { buildPaginationQuery } from '@/common/utils/table.utils'

const fetchPromotions = createAsyncThunk(
  'promotions/fetchPromotions',
  async (params: { tableParams: TablePaginationConfig; filter?: string }) => {
    const response = await api(
      HttpMethods.GET,
      `${
        buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/deals/all-deals`, params.tableParams) +
        `&filter=${params.filter}`
      }`,
    )

    return response.data
  },
)

const fetchPromotionsConfigData = createAsyncThunk('promotions/fetchPromotionsConfigData', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/deals`)
  return response.data
})

const fetchPromotionById = createAsyncThunk('promotions/fetchPromotionById', async (promotionId: number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/deals/${promotionId}`)
  return response.data[0]
})

const generatePromotion = createAsyncThunk('promotions/generatePromotion', async (data: any) => {
  const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/deals`, data)
  return response.data
})

export const promotionsService = {
  fetchPromotions,
  fetchPromotionsConfigData,
  generatePromotion,
  fetchPromotionById,
}
